import React, { useState } from 'react';
import { useForm } from '../../../../Components/UseForm';
import Modal from '../../../../Components/Modal';
import Controls from '../../../../Components/Controls/Controls';
import API from '../../../../libs/ServerAPIs';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { Grid, Button, Slide } from '@mui/material';

function ModalAddUser({
    openModal,
    title,
    set_refresh_data,
    var_refresh_data,
    closeModal,
}) {
    /* VARIABLE DECLARATION
    -------------------------------------------------------------------------------------*/

    const initValues = {
        add_user_email: '',
        first_name: '',
        last_name: '',
    };
    const [loading, set_loading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { var_values, var_errors, set_errors, resetForm, handleInputChange } =
        useForm(initValues);

    const validateFields = () => {
        let temp = { ...var_errors };
        var isValid = true;
        if (
            var_values &&
            !var_values.first_name &&
            var_values.first_name === ''
        ) {
            temp.first_name = 'First name field is required.';
            isValid = false;
        }
        if (
            var_values &&
            !var_values.last_name &&
            var_values.last_name === ''
        ) {
            temp.last_name = 'Last name field is required.';
            isValid = false;
        }
        if (
            (var_values &&
                !var_values.add_user_email &&
                var_values.add_user_email === '') ||
            !new RegExp(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ).test(var_values.add_user_email)
        ) {
            temp.add_user_email = 'Please enter valid email address.';
            isValid = false;
        }
        set_errors(temp);
        return isValid;
    };

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/
    async function handleAddUser() {
        if (validateFields()) {
            set_loading(true);
            var api_url = `/api/v1/sb/${
                window.location.pathname.split('/')[3]
            }/users`;
            await API.post(api_url, {
                firstName: var_values.first_name,
                lastName: var_values.last_name,
                emails: var_values.add_user_email,
            })
                .then((res) => {
                    if (!res.error) {
                        set_refresh_data(!var_refresh_data);
                        resetForm();
                    }
                    set_loading(false);
                    showSnackbar(
                        res.error ? 'error' : 'success',
                        res.message ? res.message : res?.errors[0]?.message
                    );
                })
                .catch((error) => {
                    return { error: true };
                });
        }
    }

    async function handleRequestUser() {
        if (validateFields()) {
            set_loading(true);
            var api_url = `/api/v1/service-binder/${
                window.location.pathname.split('/')[2]
            }/advance-view-request`;
            await API.post(api_url, {
                firstName: var_values.first_name,
                lastName: var_values.last_name,
                emails: var_values.add_user_email,
            })
                .then((res) => {
                    if (!res.error) {
                        resetForm();
                        showSnackbar(
                            res.error ? 'error' : 'success',
                            res.message ? res.message : res?.errors[0]?.message
                        );
                    }

                    if (res?.token) {
                        closeModal();
                        showSnackbar(
                            'success',
                            'Please wait you will be redirected to Advance View'
                        );
                        setTimeout(() => {
                            window.open(
                                `/service-binder/sb_demo?token=${res?.token}/`,
                                '_self'
                            );
                        }, '5000');
                    }
                    set_loading(false);
                })
                .catch((error) => {
                    return { error: true };
                });
        }
    }

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/

    function showSnackbar(variant, message) {
        enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            TransitionComponent: Slide,
        });
    }

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <Modal
            title={title}
            openModal={openModal}
            closeModal={closeModal}
            width="xs"
            dialogActions={
                <>
                    <Button onClick={closeModal}>Close</Button>
                    <LoadingButton
                        loading={loading}
                        disabled={loading}
                        variant="contained"
                        color="secondary"
                        onClick={
                            title === 'Add User'
                                ? handleAddUser
                                : handleRequestUser
                        }
                    >
                        {title === 'Add User' ? 'Add' : 'Submit'}
                    </LoadingButton>
                </>
            }
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controls.Input
                        name="first_name"
                        label="First Name"
                        value={var_values.first_name}
                        error={var_errors.first_name}
                        onChange={handleInputChange}
                        type="text"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controls.Input
                        name="last_name"
                        label="Last Name"
                        value={var_values.last_name}
                        error={var_errors.last_name}
                        onChange={handleInputChange}
                        type="text"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controls.Input
                        name="add_user_email"
                        label="Email"
                        value={var_values.add_user_email}
                        error={var_errors.add_user_email}
                        onChange={handleInputChange}
                        type="email"
                    />
                </Grid>
            </Grid>
        </Modal>
    );
}

export default ModalAddUser;
