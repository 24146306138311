import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PublicRoute } from '../Components/PublicRoute/PublicRoute';
import Loader from '../Components/Loader';

const Login = lazy(() => import('../Pages/Login'));
const SignUp = lazy(() => import('../Pages/Signup'));
const RecoverPassword = lazy(() => import('../Pages/RecoverPassword'));
const ResetPassword = lazy(() => import('../Pages/ResetPassword'));
const LocationPestSighting = lazy(() => import('../Pages/AddPestSighting'));
const ClientDashboard = lazy(() => import('../Pages/ClientDashboard'));
const ClientPestSighting = lazy(() => import('../Pages/ClientPestSighting'));
const ClientReports = lazy(() => import('../Pages/ClientReports'));
const AdminDashboard = lazy(() => import('../Pages/AdminDashboard'));
const Program = lazy(() => import('../Pages/Program/program'));
const Maps = lazy(() => import('../Pages/Maps/maps'));
const AdminLogbook = lazy(() => import('../Pages/AdminLogbook'));
const AdminSettings = lazy(() => import('../Pages/AdminSettings'));
const AdminServiceBinder = lazy(() => import('../Pages/AdminServiceBinder'));
const AdminDocuments = lazy(() => import('../Pages/AdminDocuments'));
const AdminManageUsers = lazy(() => import('../Pages/AdminManageUsers'));
const AdminPestSighting = lazy(() => import('../Pages/AdminPestSighting'));
const AdminReports = lazy(() => import('../Pages/AdminReports'));
const AdminHelp = lazy(() => import('../Pages/AdminHelp'));
const ClientHelp = lazy(() => import('../Pages/ClientHelp'));
const AdminDocumentTemplates = lazy(
    () => import('../Pages/AdminDocumentTemplates')
);
const AdminSDSCategories = lazy(() => import('../Pages/AdminSDSCategories'));
const AdminDocumentEditLicense = lazy(
    () => import('../Pages/AdminDocumentEditLicense')
);
const AdminEditLogbook = lazy(() => import('../Pages/AdminEditLogbook'));
const LicensingTraining = lazy(() => import('../Pages/LicensingTraining'));
const NotFound = lazy(() => import('../Pages/NotFound/not-found'));
const AdminPageNotFound = lazy(() => import('../Pages/AdminPageNotFound/not-found'));
const ClientPageNotFound = lazy(() => import('../Pages/ClientPageNotFound/not-found'));
const SBPageNotFound = lazy(() => import('../Pages/SBPageNotFound/not-found'));
const Materials = lazy(() => import('../Pages/Materials'));
const ServiceBinderDashboard = lazy(
    () => import('../Pages/ServiceBinderDashboard')
);
const SBSDSLabels = lazy(
    () => import('../Pages/ServiceBinderDocuments/sds-lables')
);
const SBLicenses = lazy(
    () => import('../Pages/ServiceBinderLiceses/ServiceTeam')
);
const SBServiceRecords = lazy(
    () => import('../Pages/SBServiceRecords/ServiceRecords')
);
const PestManagementProgram = lazy(
    () => import('../Pages/SBPestManagementProgram')
);
const AdminAdvanceView = lazy(() => import('../Pages/AdminAdvanceView'));
const SBPestSighting = lazy(() => import('../Pages/SBPestSighting'));
const SBConditionsReport = lazy(() => import('../Pages/SBConditionsReport'));
const SBDeviceTrending = lazy(() => import('../Pages/SBDeviceTrending'));
const SBPestTrending = lazy(() => import('../Pages/SBPestTrending'));
const SBInspectionReport = lazy(() => import('../Pages/SBInspectionReport'));
const SBMaps = lazy(() => import('../Pages/SBMaps'));
const SBHelp = lazy(() => import('../Pages/SBHelp'));
const LinkExpired = lazy(() => import('../Pages/LinkExpired'));
const Alerts = lazy(() => import('../Pages/Alerts'));

export function RenderRoutes() {
    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/register" element={<SignUp />} />
                <Route path="/recover-password" element={<RecoverPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route
                    path="/sighting/:locationId"
                    element={<LocationPestSighting />}
                />
                <Route path={'/link-expired'} element={<LinkExpired />} />

                <Route path={'/service-binder'} element={<PublicRoute />}>
                    <Route path={':sId'} element={<PublicRoute />}>
                        <Route
                            index={true}
                            element={<ServiceBinderDashboard />}
                        />
                        <Route
                            path={'service-records'}
                            element={<SBServiceRecords />}
                        />
                        <Route
                            path={'pest-trending-report'}
                            element={<SBPestTrending />}
                        />
                        <Route path={'help-tutorial'} element={<SBHelp />} />
                        <Route
                            path={'pest-management'}
                            element={<PestManagementProgram />}
                        />
                        <Route path={'sds-labels'} element={<SBSDSLabels />} />
                        <Route
                            path={'documents-license'}
                            element={<SBLicenses />}
                        />
                        <Route
                            path={'pest-sighting'}
                            element={<SBPestSighting />}
                        />
                        <Route
                            path={'conditions-report'}
                            element={<SBConditionsReport />}
                        />
                        <Route
                            path={'device-trending-report'}
                            element={<SBDeviceTrending />}
                        />
                        <Route path={'maps'} element={<SBMaps />} />
                        <Route
                            path={'inspection-report'}
                            element={<SBInspectionReport />}
                        />
                        <Route
                            path={'*'}
                            isCustomer={true}
                            element={<SBPageNotFound />}
                        />
                    </Route>
                </Route>

                <Route path={'/client'} element={<PublicRoute />}>
                    <Route
                        path={':cId'}
                        isCustomer={true}
                        element={<PublicRoute />}
                    >
                        <Route
                            index={true}
                            isCustomer={true}
                            element={<ClientDashboard />}
                        />
                        <Route
                            path={'reports'}
                            isCustomer={true}
                            element={<ClientReports />}
                        />
                        <Route
                            path={'maps'}
                            isCustomer={true}
                            element={<Maps />}
                        />
                        <Route
                            path={'materials'}
                            isCustomer={true}
                            element={<Materials />}
                        />
                        <Route
                            path={'licensing-and-training'}
                            isCustomer={true}
                            element={<LicensingTraining />}
                        />
                        <Route
                            path={'programs'}
                            isCustomer={true}
                            element={<Program />}
                        />
                        <Route
                            path={'pest-sighting'}
                            isCustomer={true}
                            element={<ClientPestSighting />}
                        />
                        <Route
                            path={'help-tutorial'}
                            isCustomer={true}
                            element={<ClientHelp />}
                        />
                        <Route
                            path={'*'}
                            isCustomer={true}
                            element={<ClientPageNotFound />}
                        />
                    </Route>
                </Route>

                <Route path={'/admin'} element={<PublicRoute />}>
                    <Route
                        isAdmin={true}
                        path={'/admin'}
                        element={<AdminDashboard />}
                    />
                    <Route
                        path={'logbooks'}
                        isAdmin={true}
                        element={<AdminLogbook />}
                    />
                    <Route
                        path={'servicebinders'}
                        isAdmin={true}
                        element={<AdminServiceBinder />}
                    />
                    <Route
                        path={'documents'}
                        isAdmin={true}
                        element={<AdminDocuments />}
                    />
                    <Route
                        path={'manage-users'}
                        isAdmin={true}
                        element={<AdminManageUsers />}
                    />
                    <Route
                        path={'pest-sighting'}
                        isAdmin={true}
                        element={<AdminPestSighting />}
                    />
                    <Route
                        path={'help-tutorial'}
                        isAdmin={true}
                        element={<AdminHelp />}
                    />
                    <Route
                        path={'adminsettings'}
                        isAdmin={true}
                        element={<AdminSettings />}
                    />
                    <Route
                        path={'report'}
                        isAdmin={true}
                        element={<AdminReports />}
                    />
                    <Route
                        path={'alerts'}
                        isAdmin={true}
                        element={<Alerts />}
                    />
                    <Route
                        path={'logbook/:location_id'}
                        isAdmin={true}
                        element={<ClientDashboard />}
                    />
                    <Route
                        path={'edit-logbook/:locID'}
                        isAdmin={true}
                        element={<AdminEditLogbook />}
                    />
                    <Route
                        path={'document/templates'}
                        isAdmin={true}
                        element={<AdminDocumentTemplates />}
                    />
                    <Route
                        path={'document/categories'}
                        isAdmin={true}
                        element={<AdminSDSCategories />}
                    />
                    <Route
                        path={'edit-license/:techID'}
                        isAdmin={true}
                        element={<AdminDocumentEditLicense />}
                    />
                    <Route
                        path={'advance-view/:advID'}
                        isAdmin={true}
                        element={<AdminAdvanceView />}
                    />
                    <Route path={'*'} isAdmin={true} element={<AdminPageNotFound />} />
                </Route>

                <Route path={'*'} element={<NotFound />} />
            </Routes>
        </Suspense>
    );
}
