import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { blueGrey } from '@mui/material/colors';
import { Drawer, Box } from '@mui/material';
import DrawerList from './DrawerList';
import API from '../../libs/ServerAPIs';

function AppDrawer({
    container,
    drawerWidth,
    open,
    drawerToggle,
    isServiceBinder = false,
    setUserDetails,
}) {
    /* VARIABLE Declaration
    -------------------------------------------------------------------------------------*/
    const blueGrey800 = blueGrey['800'];
    const location = useLocation();

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {
        const unloadCallback = async (event) => {
            event.preventDefault();
            await API.get('/api/v1/auth/logout').then((res) => {
                if (res.message) {
                    setUserDetails(null);
                    if (res.redirectURL) {
                        window.open(res.redirectURL);
                    }
                }
            });
            return;
        };
        isServiceBinder &&
            window.addEventListener('beforeunload', unloadCallback);
        return () =>
            isServiceBinder &&
            window.removeEventListener('beforeunload', unloadCallback);
    }, [isServiceBinder]);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* RENDER APP
    -------------------------------------------------------------------------------------*/

    return (
        <>
            {!location.pathname.includes('admin/edit-logbook') && (
                <Box
                    component="nav"
                    sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
                >
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={open}
                        onClose={drawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', lg: 'none' },
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: drawerWidth,
                                backgroundColor: {
                                    xs: 'primary.dark',
                                    lg: 'primary.main',
                                },
                                top: { xs: '56px', sm: '64px' },
                                border: 'none',
                                height: {
                                    xs: 'calc(100% - 56px)',
                                    sm: 'calc(100% - 64px)',
                                },
                            },
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: '0',
                                right: '0',
                            }}
                        >
                            <img
                                src="/images/sprague_logbook_fly.png"
                                alt="icon fly"
                            />
                        </Box>

                        <DrawerList />
                    </Drawer>

                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', lg: 'block' },
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: drawerWidth,
                                backgroundColor: blueGrey800,
                                color: '#fff',
                                border: 'none',
                                top: { xs: '56px', sm: '64px' },
                                height: {
                                    xs: 'calc(100% - 56px)',
                                    sm: 'calc(100% - 64px)',
                                },
                            },
                        }}
                        open
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: '0',
                                right: '0',
                            }}
                        >
                            <img
                                src="/images/sprague_logbook_fly.png"
                                alt="icon fly"
                            />
                        </Box>

                        <DrawerList />
                    </Drawer>
                </Box>
            )}
        </>
    );
}

export default AppDrawer;
