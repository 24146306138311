import React, {
    useState,
    createContext,
    useContext,
    useEffect,
    useRef,
} from 'react';
import API from '../libs/ServerAPIs';

const AppContext = createContext();

const ContextProvider = ({ children }) => {
    /* VARIABLE DECLERATION
    -------------------------------------------------------------------------------------*/
    const [var_visibleFrame, set_visibleFrame] = useState(false);
    const [var_drawer_open, set_drawer_open] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [global_isDisabled, set_global_isDisabled] = useState(false);
    const [isLoadingData, set_loading_data] = useState(false);
    const [var_refresh_data, set_refresh_data] = useState(false);
    const [var_download_lb_message, set_download_lb_message] = useState(false);
    const [var_download_progress, set_download_progress] = useState(false);
    const [user_details, setUserDetails] = useState(null);
    const [primary_user, setPrimaryUser] = useState(null);
    const [secondary_user, setSecondaryUser] = useState(null);
    const [selected_branch, setSelectedBranch] = useState(null);
    const [user_branches, set_user_branches] = useState([]);
    const [user_locations_with_branches, setUserLocationsWithBranches] =
        useState({});
    const [user_branch_locations, set_user_branch_locations] = useState([]);
    const [selected_branch_locations, set_selected_branch_locations] = useState(
        []
    );
    const [isMobile, set_isMobile] = useState(window.innerWidth <= 768);
    const [selected_all_locations, set_selected_all_locations] =
        useState(false); // Flag set to true when all locations in dropdown are selected
    const [show_branch_dropdown, set_show_branch_dropdown] = useState(false); // Branch selection dropdown in App top bar for sprague users
    const [show_location_dropdown, set_show_location_dropdown] =
        useState(false); // Locations selection dropdown in App top bar for sprague users
    const [
        show_customer_locations_dropdown,
        set_show_customer_locations_dropdown,
    ] = useState(false); // Locations selection dropdown in App top bar for customer admin / customer user
    const [show_service_binder_link, set_show_service_binder_link] =
        useState(false);
    const [show_logbook_account_link, set_show_logbook_account_link] =
        useState(false);
    const [app_top_bar_title, set_app_top_bar_title] = useState('');
    const [var_document_tab, set_document_tab] = useState('global-documents');
    const prevNotificationRef = useRef();
    const [isActiveSession, setIsActiveSession] = useState(false);
    const [isUserBranchDisabled, setIsUserBranchDisabled] = useState(false);
    const [isInnerPageImpersonate, setisInnerPageImpersonate] = useState(true);
    const [current_routing, set_current_routing] = useState();

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {}, [prevNotificationRef.current]);

    const handleWindowSizeChange = () => {
        if (window.innerWidth <= 768) {
            set_isMobile(true);
        } else {
            set_isMobile(false);
        }
    };

    /* EVENT LISTENERS
		-------------------------------------------------------------------------------------*/
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        if (
            user_details &&
            user_details.role_type !== 'Customer Admin' &&
            user_details.role_type !== 'Customer'
        ) {
            if (
                selected_branch &&
                user_locations_with_branches.locations &&
                user_locations_with_branches.locations.hasOwnProperty(
                    selected_branch
                )
            ) {
                set_user_branch_locations([
                    { value: 'all', label: 'All' },
                    ...user_locations_with_branches.locations[selected_branch],
                ]);
                localStorage.setItem('selectedBranch', selected_branch);
            } else {
                set_user_branch_locations([]);
            }
        }
    }, [selected_branch]);

    useEffect(() => {
        if (
            user_branches &&
            user_branches.length &&
            !show_customer_locations_dropdown
        ) {
            let selectedBranch =
                (user_details && user_details.defaultBranch) ||
                user_branches[0].value;
            const storageBranch = localStorage.getItem('selectedBranch');
            if (
                !user_details.isImpersonate &&
                storageBranch &&
                user_branches
                    .map((branch) => branch.value)
                    .includes(storageBranch * 1)
            ) {
                selectedBranch = storageBranch;
            }
            setSelectedBranch(selectedBranch);
        } else {
            setSelectedBranch(null);
        }
    }, [user_branches]);

    useEffect(() => {
        if (
            user_locations_with_branches &&
            user_locations_with_branches.branches &&
            user_locations_with_branches.branches.length &&
            !show_customer_locations_dropdown
        ) {
            set_user_branches(user_locations_with_branches.branches);
        }
        if (
            user_details &&
            user_details.type !== 'sprague' &&
            user_locations_with_branches.length
        ) {
            set_user_branch_locations(user_locations_with_branches);
        }
    }, [user_locations_with_branches]);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    async function checkAuthSession() {
        const userInfo = await API.get('/api/v1/auth');
        return userInfo;
    }

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    /* ContextProvider value
    -------------------------------------------------------------------------------------*/
    const data = {
        currentRouting: [current_routing, set_current_routing],
        auth: [isAuth, setIsAuth],
        frame: [var_visibleFrame, set_visibleFrame],
        drawerOpen: [var_drawer_open, set_drawer_open],
        userData: [user_details, setUserDetails],
        userBranches: [user_branches],
        mobileCheck: [isMobile, set_isMobile],
        userBranchLocations: [user_branch_locations, set_user_branch_locations],
        refresh_data: [var_refresh_data, set_refresh_data],
        download_lb_message: [var_download_lb_message, set_download_lb_message],
        download_progress: [var_download_progress, set_download_progress],
        loading_data: [isLoadingData, set_loading_data],
        checkAuth: checkAuthSession,
        selectedBranch: [selected_branch, setSelectedBranch],
        selectedBranchLocations: [
            selected_branch_locations,
            set_selected_branch_locations,
        ],
        selectedAllLocations: [
            selected_all_locations,
            set_selected_all_locations,
        ],
        showAppTopBarBranchField: [
            show_branch_dropdown,
            set_show_branch_dropdown,
        ],
        showCustomerLocationsDropdown: [
            show_customer_locations_dropdown,
            set_show_customer_locations_dropdown,
        ],
        showAppTopBarLocationField: [
            show_location_dropdown,
            set_show_location_dropdown,
        ],
        showServiceBinderLink: [
            show_service_binder_link,
            set_show_service_binder_link,
        ],
        showLogbookLink: [
            show_logbook_account_link,
            set_show_logbook_account_link,
        ],
        appTopBarTitleAsPerPage: [app_top_bar_title, set_app_top_bar_title],
        document_tab: [var_document_tab, set_document_tab],
        current_user_branches_and_locations: [
            user_locations_with_branches,
            setUserLocationsWithBranches,
        ],
        primaryUser: [primary_user, setPrimaryUser],
        secondaryUser: [secondary_user, setSecondaryUser],
        activeSession: [isActiveSession, setIsActiveSession],
        InnerImpersonate: [isInnerPageImpersonate, setisInnerPageImpersonate],
        userBranchDisabled: [isUserBranchDisabled, setIsUserBranchDisabled],
        globalIsDisabled: [global_isDisabled, set_global_isDisabled],
    };

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return <AppContext.Provider value={data}>{children}</AppContext.Provider>;
};

const useData = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useData can only be used inside AppContext');
    }
    return context;
};

export { ContextProvider, useData };
