import React  from "react";
import { Outlet } from "react-router-dom";

export function PublicRoute() {
    // const { auth } = useData(useData);
    // const [isAuth] = auth;

  return(
     <Outlet/>
    ) }
// import React from 'react'
// import { Navigate, useLocation } from 'react-router-dom'
// import { useData } from '../Context/Context';

// export function PublicRoute({ children }) {
//     const { auth } = useData(useData);
//     const [isAuth] = auth;
//     let location = useLocation()

//     return isAuth ? (
//         <Navigate
//             to={{
//                 pathname: 'dashboard',
//                 state: { from: location }
//             }}
//         />
//     ) : (
//         children
//     )
// }