import React, { useEffect } from 'react';
import { Box, CircularProgress, keyframes } from '@mui/material';
import LogoWhite from '../../logo-blue.svg';
import styled from '@emotion/styled';

function Spinner(spragueSpinner = false) {
    const spinloader = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

    const zooming = keyframes`
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.5);
    }

    100% {
        transform: rotate(1);
    }
`;

    const LoaderContainer = styled(Box)({
        height: '100%',
        width: '100%',
        left: '0',
        top: '0',
        zIndex: '1010',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        backdropFilter: 'blur(4px)',
        position: 'fixed',
    });

    const Spinner = styled(Box)({
        border: '16px solid #37474F',
        borderTop: '16px solid #BD2531',
        borderRadius: '50%',
        width: '120px',
        height: '120px',
        animation: `${spinloader} 2s linear infinite`,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginLeft: '-60px',
        marginTop: '-60px',
        boxSizing: 'border-box',
    });

    const Logo = styled(Box)({
        width: '90px',
        height: '90px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginLeft: '-45px',
        marginTop: '-45px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    });

    const StyledImg = styled('img')({
        animation: `${zooming} 5s linear infinite`,
        maxWidth: '100%',
    });
    /* VARIABLE DECLARATION
    -------------------------------------------------------------------------------------*/

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {}, []);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <>
            {!spragueSpinner && (
                <Box
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        backdropFilter: 'blur(4px)',
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        bottom: '0',
                        left: '0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: '111',
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {spragueSpinner && (
                <LoaderContainer>
                    <Spinner></Spinner>
                    <Logo>
                        <StyledImg src={LogoWhite} alt="" />
                    </Logo>
                </LoaderContainer>
            )}
        </>
    );
}

export default Spinner;
