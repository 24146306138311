import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@mui/material';

function Modal(props) {
    const {
        title,
        subtitle,
        openModal,
        closeModal,
        children,
        width,
        dialogActions,
        contentNoPadding,
        customStyle,
    } = props;
    return (
        <Dialog
            open={openModal}
            onClose={closeModal}
            maxWidth={width}
            fullWidth
            sx={{
                ...customStyle,
                '& .MuiDialog-paper': {
                    mx: '0',
                    width: { xs: 'calc(100% - 32px)', md: 'calc(100% - 64px)' },
                },
            }}
            disableEnforceFocus={true}
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: { xs: '17px', sm: '18px', md: '20px' },
                    lineHeight: { xs: '1.2', sm: '1.3', md: '1.6' },
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>{title}</span>
                    {subtitle && (
                        <span style={{ fontSize: '16px' }}> {subtitle}</span>
                    )}
                </div>

                <IconButton onClick={closeModal} sx={{ ml: 'auto' }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent
                dividers
                sx={{ ...(contentNoPadding && { p: '0' }) }}
            >
                {children}
            </DialogContent>

            <DialogActions children={dialogActions} />
        </Dialog>
    );
}

export default Modal;
