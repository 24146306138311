import React from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';

function MobileMenu({ anchorEl, handleMenuOpen, handleMobileMenuClose }) {
    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={handleMenuOpen}
                onClose={handleMobileMenuClose}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    component="a"
                    href="https://lb.spraguepest.com/"
                    target="_blank"
                    onClick={handleMobileMenuClose}
                >
                    <IconButton size="small" color="inherit">
                        <LinkIcon />
                    </IconButton>
                    <p>Logbook</p>
                </MenuItem>
                <MenuItem
                    component="a"
                    href="https://lb.spraguepest.com/"
                    target="_blank"
                    onClick={handleMobileMenuClose}
                >
                    <IconButton size="small" color="inherit">
                        <LinkIcon />
                    </IconButton>
                    <p>Service Binder</p>
                </MenuItem>
            </Menu>
        </>
    );
}

export default MobileMenu;
