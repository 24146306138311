import {
    FormControl,
    FormHelperText,
    FormControlLabel,
    Checkbox as MuiCheckbox,
} from '@mui/material';
import React from 'react';

export default function Checkbox(props) {
    const {
        name,
        label,
        value,
        onChange,
        disabled = false,
        error = null,
        color = 'primary',
        ...other
    } = props;
    const convertToDefaultEventPara = (name, value) => ({
        target: {
            name,
            value,
        },
    });
    return (
        <>
            <FormControl {...(error && { error: true })}>
                <FormControlLabel
                    control={
                        <MuiCheckbox
                            name={name}
                            color={color}
                            checked={value}
                            disabled={disabled}
                            onChange={(e) =>
                                onChange(
                                    convertToDefaultEventPara(
                                        name,
                                        e.target.checked
                                    )
                                )
                            }
                        />
                    }
                    label={label}
                    {...other}
                />
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        </>
    );
}
