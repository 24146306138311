import { styled } from '@mui/material/styles';
import {
    List,
    ListItemText,
    ListItemIcon,
    ListItemButton,
} from '@mui/material';

export const StyledList = styled(List)({
    paddingTop: '20px',
});

export const StyledListItem = styled(ListItemText)({
    padding: '0',
});

export const StyledLink = styled(ListItemButton)(({ theme }) => ({
    color: 'rgba(255,255,255, 0.75)',
    '&:hover': {
        color: '#fff',
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
    '&:after': {
        content: '""',
        width: '4px',
        height: '100%',
        position: 'absolute',
        top: '0',
        right: '0',
    },
    '&.active': {
        backgroundColor: '#fff',
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: '#fff',
            color: theme.palette.secondary.main,
        },
        '&::after': {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    '&.Mui-selected.Mui-focusVisible': {
        backgroundColor: '#fff',
    },
}));

export const StyledIcon = styled(ListItemIcon)({
    color: 'inherit',
    minWidth: '44px',
});

export const StyledText = styled(ListItemText)({
    fontSize: '16px',
    letterSpacing: '0.2px',
});
