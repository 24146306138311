import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        h1: {
            fontSize: '32px',
            color: '#37474f',
            fontWeight: 500,
        },
        h2: {
            fontSize: '26px',
            color: '#37474f',
            fontWeight: 500,
        },
        h3: {
            fontSize: '20px',
            color: '#37474f',
            fontWeight: 500,
        },
        h4: {
            fontSize: '18px',
            color: '#37474f',
            fontWeight: 500,
        },
        h5: {
            fontSize: '16px',
            color: '#37474f',
            fontWeight: 500,
        },
        h6: {
            fontSize: '14px',
            color: '#37474f',
            fontWeight: 500,
        },
    },

    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: 'black',
                    backgroundColor: 'white',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    paddingTop: '18px',
                    paddingBottom: '18px',
                    color: '#37474f',
                    fontSize: '13px',
                    whiteSpace: 'nowrap',
                },
                body: {
                    fontSize: '13px',
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#37474f',
            light: '#607d8b',
            dark: '#263238',
        },
        secondary: {
            main: '#bd2531',
            light: '#f65c5b',
            dark: '#85000b',
        },
        text: {
            primary: '#455a64',
            secondary: '#78909c',
            disabled: 'rgba(0,0,0,0.38)',
        },
    },
});
