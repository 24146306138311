import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { ContextProvider } from './Context/Context';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import { SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <ContextProvider>
            <ThemeProvider theme={theme}>
                <SnackbarProvider>
                    <Router>
                        <App />
                    </Router>
                </SnackbarProvider>
            </ThemeProvider>
        </ContextProvider>
    </React.StrictMode>,
);
