import React, { useState, useEffect } from 'react';
import { useForm } from '../UseForm';
import Modal from '../Modal';
import API from '../../libs/ServerAPIs';
import Controls from '../Controls/Controls';
import Spinner from '../../Components/Spinner';
import { Grid, Button, LinearProgress, Alert, Collapse } from '@mui/material';

function ModalCreateLogbook({ closeModal, openModal }) {
    /* VARIABLE DECLERATION
    -------------------------------------------------------------------------------------*/
    const [template_documents, set_template_documents] = useState([]);
    const [var_show_progressbar, set_show_progressbar] = useState(false);
    const [var_show_alert, set_show_alert] = useState(false);
    const [var_loading, set_loading] = useState(false);
    const [var_disable, set_disable] = useState(false);
    const [var_message, set_message] = useState('');
    const initValues = {
        location_number: '',
        location_name: '',
        location_code: '',
        address: '',
        branch: '',
        client_contact_name: '',
        email: '',
        phone_number: '',
        ext: '',
        logbook_template: '',
    };
    const {
        var_values,
        set_values,
        var_errors,
        set_errors,
        resetForm,
        handleInputChange,
    } = useForm(initValues);

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {
        if (openModal) {
            set_show_progressbar(false);
            set_show_alert(false);
            getdocumentTemplatesLookup();
        }
    }, [openModal]);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    function editedCloseModal() {
        resetForm();
        closeModal();
    }

    async function getLogbookData(location_number) {
        const requestUrl = `/api/v1/location/pestpac/by-code/${location_number}`;
        set_show_progressbar(true);
        set_disable(true);
        await API.get(requestUrl)
            .then((value) => {
                if (!value.error) {
                    var temp_doc;
                    value.isOrganic === 1
                        ? template_documents.map((val) =>
                              val.label === 'Organic'
                                  ? (temp_doc = val.value)
                                  : ''
                          )
                        : template_documents.map((val) =>
                              val.label === 'Standard'
                                  ? (temp_doc = val.value)
                                  : ''
                          );
                    set_values({
                        ...var_values,
                        location_name: value.name,
                        address: value.address,
                        branch: value.branch,
                        client_contact_name: value.clientName,
                        email: value.clientEmail,
                        phone_number: value.phone,
                        ext: value.phoneExtension,
                        location_code: value.code,
                        logbook_template: temp_doc,
                    });
                    set_show_progressbar(false);
                    set_show_alert(false);
                    set_disable(false);
                }
                if (
                    value.message === 'No Location Found.' ||
                    value.message === 'Location already exist.'
                ) {
                    set_message(value.message);
                    set_show_progressbar(false);
                    set_show_alert(true);
                    set_disable(true);
                }
            })
            .catch((error) => {
                return { error: true };
            });
    }

    async function addLogbook() {
        if (validate()) {
            set_loading(true);
            await API.post(`/api/v1/location`, {
                locationID: var_values.location_number,
                locationNumber: `${var_values.location_code}`,
                locationName: var_values.location_name,
                address: var_values.address,
                branch: var_values.branch,
                clientName: var_values.client_contact_name,
                clientEmail: var_values.email,
                phone: var_values.phone_number,
                phoneExtension: var_values.ext,
                templateId: var_values.logbook_template,
            })
                .then((res) => {
                    if (!res.error) {
                        closeModal();
                        resetForm();
                    }

                    if (res.error) {
                        set_message(res.message);
                        set_show_progressbar(false);
                        set_show_alert(true);
                        set_disable(true);
                    }

                    set_loading(false);
                })
                .catch((error) => {
                    // console.log("Error:", error);
                    return { error: true };
                });
        }
    }

    async function getdocumentTemplatesLookup() {
        set_loading(true);
        const requestUrl = '/api/doc/document-template/lookup';
        await API.get(requestUrl, true)
            .then((value) => {
                if (!value.error) {
                    value.map((val) =>
                        val.label === 'Standard'
                            ? set_values({
                                  ...var_values,
                                  logbook_template: val.value,
                              })
                            : ''
                    );
                    set_template_documents(value);
                    set_loading(false);
                }
            })
            .catch((error) => {
                // console.log("Error:", error);
                return { error: true };
            });
    }

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    const handleLogbookData = (event) => {
        if (
            (event.target.value && event.target.value.length > 3) ||
            event.target.value.length < 1
        ) {
            getLogbookData(event.target.value);
        } else {
            set_disable(true);
        }
    };

    const validate = (fieldValues = var_values) => {
        let temp = { ...var_errors };
        let isValid = true;
        if (!fieldValues.email || fieldValues.email === '') {
            temp.email = 'Please enter email.';
            isValid = false;
        }
        if (!fieldValues.phone_number || fieldValues.phone_number === '') {
            temp.phone_number = 'Please enter phone number.';
            isValid = false;
        }
        if (
            !fieldValues.client_contact_name ||
            fieldValues.client_contact_name === ''
        ) {
            temp.client_contact_name = 'Please enter Client Name.';
            isValid = false;
        }
        set_errors({
            ...temp,
        });
        return isValid;
    };

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <Modal
            title="Create Logbook"
            openModal={openModal}
            closeModal={editedCloseModal}
            width="xs"
            dialogActions={
                <>
                    <Button onClick={editedCloseModal}>Close</Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={var_disable}
                        onClick={addLogbook}
                    >
                        Save
                    </Button>
                </>
            }
        >
            <Collapse in={var_show_alert}>
                <Alert
                    severity="warning"
                    action={
                        <Button
                            color="inherit"
                            size="small"
                            onClick={() => set_show_alert(false)}
                        >
                            Dismiss
                        </Button>
                    }
                    sx={{ mb: '16px' }}
                >
                    {var_message}
                </Alert>
            </Collapse>
            {var_loading && <Spinner />}
            <Grid container spacing={2}>
                <Grid item xs={7} sm={8}>
                    <Controls.Input
                        name="location_number"
                        label="Location #"
                        value={var_values.location_number}
                        error={var_errors.location_number}
                        onKeyUp={handleLogbookData}
                        onChange={handleInputChange}
                        type="text"
                    />
                    {var_show_progressbar && <LinearProgress />}
                </Grid>
                <Grid item xs={5} sm={4}>
                    <Button
                        variant="contained"
                        fullWidth
                        sx={{ height: '53px' }}
                        disabled={
                            var_values?.location_number?.length === 0 ||
                            var_values?.location_number?.length < 3
                        }
                        onClick={() => {
                            getLogbookData(var_values.location_number);
                        }}
                    >
                        Check from PestPac
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Controls.Input
                        name="location_name"
                        label="Location Name"
                        value={var_values.location_name}
                        error={var_errors.location_name}
                        onChange={handleInputChange}
                        disabled={true}
                        type="text"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controls.Input
                        name="address"
                        label="Address"
                        value={var_values.address}
                        error={var_errors.address}
                        onChange={handleInputChange}
                        disabled={true}
                        type="text"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controls.Input
                        name="branch"
                        label="Branch"
                        value={var_values.branch}
                        error={var_errors.branch}
                        onChange={handleInputChange}
                        disabled={true}
                        type="text"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controls.Input
                        name="client_contact_name"
                        label="Client Contact Name"
                        value={var_values.client_contact_name}
                        error={var_errors.client_contact_name}
                        onChange={handleInputChange}
                        type="text"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controls.Input
                        name="email"
                        label="Email"
                        value={var_values.email}
                        error={var_errors.email}
                        onChange={handleInputChange}
                        type="text"
                    />
                </Grid>
                <Grid item xs={8}>
                    <Controls.Input
                        name="phone_number"
                        label="Phone Number"
                        value={var_values.phone_number}
                        error={var_errors.phone_number}
                        onChange={handleInputChange}
                        type="text"
                    />
                </Grid>
                <Grid item xs={4}>
                    <Controls.Input
                        name="ext"
                        label="Ext"
                        value={var_values.ext}
                        error={var_errors.ext}
                        onChange={handleInputChange}
                        type="text"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controls.Select
                        name="logbook_template"
                        label="Template"
                        value={var_values.logbook_template}
                        error={var_errors.logbook_template}
                        onChange={handleInputChange}
                        options={template_documents || []}
                    />
                </Grid>
            </Grid>
        </Modal>
    );
}

export default ModalCreateLogbook;
