import React from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Dialog,
    DialogContent,
    Box,
    IconButton,
    Button,
    Typography,
} from '@mui/material';

function ModalConfirm({
    title,
    text,
    type,
    openModal,
    closeModal,
    width = 'xs',
    handleConfirm,
    loading,
}) {
    /* VARIABLE DECLARATION
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <Dialog
            open={openModal}
            onClose={closeModal}
            maxWidth={width}
            fullWidth
        >
            <DialogContent>
                <Box sx={{ position: 'absolute', right: '5px', top: '5px' }}>
                    <IconButton onClick={closeModal}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box sx={{ textAlign: 'center', pt: '16px' }}>
                    {type === 'error' && (
                        <ErrorOutlineIcon
                            sx={{ fontSize: '80px', color: 'error.main' }}
                        />
                    )}
                    {type === 'warning' && (
                        <WarningAmberIcon
                            sx={{ fontSize: '80px', color: 'warning.main' }}
                        />
                    )}
                    {type === 'success' && (
                        <CheckIcon
                            sx={{ fontSize: '80px', color: 'success.main' }}
                        />
                    )}

                    <Typography variant="h3" sx={{ mt: '20px' }}>
                        {title}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ fontSize: '18px', mt: '12px', color: 'grey.500' }}
                    >
                        {text}
                    </Typography>
                </Box>

                <Box sx={{ textAlign: 'center', mt: '16px' }}>
                    <Button
                        sx={{ mx: '2px' }}
                        color="primary"
                        variant="contained"
                        onClick={closeModal}
                    >
                        No
                    </Button>
                    <LoadingButton
                        loading={loading}
                        color="secondary"
                        variant="contained"
                        onClick={handleConfirm}
                        sx={{ mx: '2px' }}
                    >
                        Yes
                    </LoadingButton>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default ModalConfirm;
