import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/system';
import { Tooltip } from '@mui/material';
import moment from 'moment';
import MomentTZ from 'moment-timezone';
import { useData } from '../../../Context/Context';

function LastLoggedin({ userMenu = false }) {
    const { userData } = useData(useData);
    const location = useLocation();
    const [user_details] = userData;
    return (
        <>
            <Box
                sx={{
                    color: 'rgba(255, 255, 255, 0.75)',
                    textAlign: 'right',
                    display: 'flex',
                    alignItems: userMenu ? 'flex-start' : 'flex-end',
                    px: !userMenu ? '4px' : '0',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <Box>Last Login</Box>

                <Box
                    sx={{
                        fontSize: '12px',
                        py: userMenu ? '4px' : '0',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {!location.pathname.includes('admin') &&
                    user_details.isCustomer &&
                    user_details.isImpersonate ? (
                        <Tooltip
                            title={`Last Login: ${
                                user_details?.location?.lastLogin
                                    ? `${moment
                                          .utc(
                                              user_details?.location?.lastLogin
                                          )
                                          .local()
                                          .format(
                                              'MMM D YYYY hh:mm A z'
                                          )} ${MomentTZ.tz(
                                          MomentTZ.tz.guess()
                                      ).zoneName()}`
                                    : 'No Login Details'
                            }`}
                            arrow
                        >
                            <Box>
                                {user_details?.location?.lastLogin
                                    ? `${moment
                                          .utc(
                                              user_details?.location?.lastLogin
                                          )
                                          .local()
                                          .format(
                                              'MMM D YYYY hh:mm A z'
                                          )} ${MomentTZ.tz(
                                          MomentTZ.tz.guess()
                                      ).zoneName()}`
                                    : 'No Login Details'}
                            </Box>
                        </Tooltip>
                    ) : (
                        <Tooltip
                            title={`Last Login: ${
                                user_details?.lastLogin
                                    ? `${moment
                                          .utc(user_details?.lastLogin)
                                          .local()
                                          .format(
                                              'MMM D YYYY hh:mm A z'
                                          )} ${MomentTZ.tz(
                                          MomentTZ.tz.guess()
                                      ).zoneName()}`
                                    : 'No Login Details'
                            }`}
                            arrow
                        >
                            <Box>
                                {user_details?.lastLogin
                                    ? `${moment
                                          .utc(user_details?.lastLogin)
                                          .local()
                                          .format(
                                              'MMM D YYYY hh:mm A z'
                                          )} ${MomentTZ.tz(
                                          MomentTZ.tz.guess()
                                      ).zoneName()}`
                                    : 'No Login Details'}
                            </Box>
                        </Tooltip>
                    )}
                </Box>
            </Box>
        </>
    );
}

export default LastLoggedin;
