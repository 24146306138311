import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { blueGrey } from '@mui/material/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AddIcon from '@mui/icons-material/Add';
import PestProgramLogbook from './PestProgramLogbook';
import LastLoggedin from './LastLoggedin';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
    Box,
    AppBar,
    Toolbar,
    IconButton,
    Button,
    Typography,
    Tooltip,
    Slide,
    CircularProgress,
} from '@mui/material';
import UserMenu from './UserMenu';
import MobileMenu from './MobileMenu';
import Logo from '../../logo.png';
import API from '../../libs/ServerAPIs';
import {
    getRecentActivityList,
    markActivitiesAsRead,
} from '../../libs/activityLog';
import { useData } from '../../Context/Context';
import appSettings from '../../config';
import MomentTZ from 'moment-timezone';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function AppTopBar({
    drawerToggle,
    openMdlPreferences,
    openMdlCreateLogbook,
    openMdlAdvView,
    isImpersonate,
}) {
    function openAlerts() {
        history('/admin/alerts');
    }

    /* VARIABLE DECLARATION
	-------------------------------------------------------------------------------------*/
    const blueGrey800 = blueGrey['800'];
    const {
        auth,
        activeSession,
        userData,
        primaryUser,
        userBranchLocations,
        selectedBranchLocations,
        selectedAllLocations,
        showCustomerLocationsDropdown,
        appTopBarTitleAsPerPage,
        showAppTopBarBranchField,
        showAppTopBarLocationField,
        showServiceBinderLink,
        showLogbookLink,
        download_lb_message,
        download_progress,
        loading_data,
    } = useData(useData);
    const [user_details, setUserDetails] = userData;
    const [, set_loading_data] = loading_data;
    const [primary_user, setPrimaryUser] = primaryUser;
    const [var_download_lb_message, set_download_lb_message] =
        download_lb_message;
    const [isMobile, set_isMobile] = useState(window.innerWidth <= 768);
    const [isActiveSession, setIsActiveSession] = activeSession;
    const [user_branch_locations] = userBranchLocations;
    const [selected_branch_locations, set_selected_branch_locations] =
        selectedBranchLocations; // Selected locations provided in context
    const [, set_selected_all_locations] = selectedAllLocations;
    // Begin: LocationsCustomAutocomplete states
    const [locations_options, set_locations_options] = useState(
        user_branch_locations
    );
    const [, set_selected_locations_options] = useState(
        selected_branch_locations
    );
    // End: LocationsCustomAutocomplete states
    // Begin: Customer dropdown states
    const [
        show_customer_locations_dropdown,
        set_show_customer_locations_dropdown,
    ] = showCustomerLocationsDropdown;
    const [, set_app_top_bar_title] = appTopBarTitleAsPerPage;
    const [, set_show_branch_dropdown] = showAppTopBarBranchField;
    const [, set_show_location_dropdown] = showAppTopBarLocationField;
    const [show_service_binder_link, set_show_service_binder_link] =
        showServiceBinderLink;
    const [show_logbook_account_link, set_show_logbook_account_link] =
        showLogbookLink;
    const [isAuth, setIsAuth] = auth;
    const [var_user_menu_elem, set_user_menu_elem] = useState(null);
    const isMenuOpen = Boolean(var_user_menu_elem);
    const [var_mobile_moreEl, set_mobile_moreEl] = useState(null);
    const isMobileMenuOpen = Boolean(var_mobile_moreEl);
    const [notifications, setNotifications] = useState([]);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [, setPrevNotificationsCount] = useState(0);
    const [totalNotificationsCount, setTotalNotificationsCount] = useState(0);
    const [var_sync_lb_message, set_sync_lb_message] = useState(false);
    const [var_last_sync_date, set_last_sync_date] = useState(
        user_details?.location?.lastSynced
    );
    const [var_service_binder_path, set_service_binder_path] = useState(false);
    const initialTotal = useRef(0);
    const [, set_notification_el] = useState(null);
    const location = useLocation();
    const history = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const previousUserBranchLocations = usePrevious(user_branch_locations);
    // const [var_downloadProgress,set_download_progress] = useState(0);
    const [var_download_progress, set_download_progress] = download_progress;
    const component = AppTopBar.name;

    const handleWindowSizeChange = () => {
        if (window.innerWidth <= 768) {
            set_isMobile(true);
        } else {
            set_isMobile(false);
        }
    };

    /* EVENT LISTENERS
		-------------------------------------------------------------------------------------*/
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);
    useEffect(() => {
        let fetchInterval;
        let fetchNotifcations;
        if (isAuth) {
            // If the user has admin / higher level access query new notifications from server periodically
            // as well as from ongoing activities
            if (
                user_details.role_type === 'Customer Admin' ||
                user_details.role_type === 'Super Admin' ||
                user_details.role_type === 'Content Manager' ||
                user_details.role_type === 'Admin'
            ) {
                fetchNotifcations =
                    user_details.type !== 'demo'
                        ? fetchNewNotifications
                        : generateDemoNotifications;

                // fetchNewNotifications from backend
                if (
                    isActiveSession &&
                    appSettings.enableNotifications === true
                ) {
                    //
                    fetchInterval = setInterval(
                        fetchNotifcations,
                        1000 * 60 * 15
                    ); // Fetch new notifications after 20000 milliseconds (20 seconds)
                } else {
                    if (fetchInterval) {
                        clearInterval(fetchInterval);
                    }
                }
            }
        }

        return () => {
            if (fetchInterval) {
                clearInterval(fetchInterval);
            }
            if (fetchNotifcations?.name) {
                API.PendingRequestsManager.abortRequest(
                    component,
                    fetchNotifcations?.name
                );
            }
        };
    }, [isActiveSession]);

    useEffect(() => {
        if (isActiveSession && appSettings.enableNotifications === true) {
            const recentNotifications = getRecentActivityList();
            setNotifications(recentNotifications);
            setNotificationsCount(recentNotifications.length);
        }
    }, [sessionStorage.activityCount]);

    useEffect(() => {
        // Reset all locations on locations change
        if (locations_options.length > 0) {
            //set_selected_branch_locations(locations_options);
            set_selected_locations_options(locations_options);
        }
        if (
            user_details.role_type === 'Customer Admin' ||
            user_details.role_type === 'Customer'
        ) {
            set_show_customer_locations_dropdown(
                [
                    '/app',
                    '/app/locations',
                    '/app/services',
                    '/app/reports',
                    //'/app/manage-users',
                    '/app/conditions',
                    '/app/report',
                ].includes(location.pathname)
            );

            set_show_branch_dropdown(false);
            set_show_location_dropdown(false);
        } else {
            set_show_branch_dropdown(
                [
                    '/app',
                    '/app/services',
                    '/app/locations',
                    '/app/reports',
                    '/app/manage-users',
                    '/app/conditions',
                ].includes(location.pathname)
            );
            set_show_location_dropdown(
                [
                    '/app',
                    '/app/services',
                    '/app/reports',
                    '/app/conditions',
                ].includes(location.pathname)
            );
        }

        if (location.pathname.includes('admin/servicebinders')) {
            set_service_binder_path(true);
        } else {
            set_service_binder_path(false);
        }

        switch (location.pathname) {
            case '/app':
                set_app_top_bar_title('Dashboard');
                break;
            case '/app/locations':
                set_app_top_bar_title('Locations');
                break;
            case '/app/services':
                set_app_top_bar_title('Services');
                break;
            case '/app/manage-users':
                set_app_top_bar_title('Manage Users');
                break;
            case '/app/conditions':
                set_app_top_bar_title('Conditions');
                break;
            case '/app/reports':
                set_app_top_bar_title('Reports');
                break;
            case '/app/settings':
                set_app_top_bar_title('Settings');
                break;
            case '/app/help':
                set_app_top_bar_title('Help');
                break;
            default:
                set_app_top_bar_title('');
                break;
        }
    }, [location.pathname]);

    /* multiselect functionality */
    useEffect(() => {
        // Added !show_customer_dropdown to prevent setting of branch locations for customer users
        if (
            user_branch_locations.length &&
            JSON.stringify(previousUserBranchLocations) !==
                JSON.stringify(user_branch_locations)
        ) {
            //if (user_branch_locations.length) {
            const locationStorage = localStorage.getItem('selectedLocations');
            const selectedLocations =
                (locationStorage && JSON.parse(locationStorage)) || [];
            if (
                selectedLocations &&
                Array.isArray(selectedLocations) &&
                selectedLocations.length &&
                selectedLocations.every((selectedLocation) =>
                    user_branch_locations
                        .map((uL) => uL.value)
                        .includes(selectedLocation.value)
                )
            ) {
                if (selectedLocations[0].value === 'all') {
                    set_selected_all_locations(true);
                }
                set_selected_branch_locations(selectedLocations);
                //set_selected_locations_options(selectedLocations);
            } else {
                set_selected_branch_locations(user_branch_locations);
                //set_selected_locations_options(user_br_locations);

                set_selected_all_locations(
                    true && !show_customer_locations_dropdown
                );
            }

            set_locations_options(user_branch_locations);
        }
    }, [user_branch_locations]);

    useEffect(() => {
        if (selected_branch_locations.length) {
            if (selected_branch_locations[0].value === 'all') {
                const checkHasLogbookAccess =
                    user_branch_locations.filter(
                        (userBrLocation) =>
                            userBrLocation.hasLogbookAccount === true
                    ).length || false;
                const checkIsServiceBinder =
                    user_branch_locations.filter(
                        (userBrLocation) =>
                            userBrLocation.isServiceBinder === true
                    ).length || false;
                set_show_service_binder_link(checkIsServiceBinder);
                set_show_logbook_account_link(checkHasLogbookAccess);
            } else {
                const checkHasLogbookAccess =
                    selected_branch_locations.filter(
                        (userBrLocation) =>
                            userBrLocation.hasLogbookAccount === true
                    ).length || false;
                const checkIsServiceBinder =
                    selected_branch_locations.filter(
                        (userBrLocation) =>
                            userBrLocation.isServiceBinder === true
                    ).length || false;
                set_show_service_binder_link(checkIsServiceBinder);
                set_show_logbook_account_link(checkHasLogbookAccess);
            }
            set_selected_locations_options(selected_branch_locations);
        }
    }, [selected_branch_locations]);

    useEffect(() => {
        if (!var_sync_lb_message) {
            updateSyncAuth();
        }
    }, [var_sync_lb_message]);

    /* multiselect functionality */

    /* ASYNC FUNCTIONS
		-------------------------------------------------------------------------------------*/
    async function updateSyncAuth() {
        const requestUrl = `/api/v1/location/${user_details?.location?.code}`;
        await API.get(requestUrl)
            .then((location) => {
                if (!location.error) {
                    set_last_sync_date(location?.lastSynced);
                }
            })
            .catch((error) => {
                return { error: true };
            });
    }

    async function fetchNewNotifications() {
        const userNotifications = await getNotifications();
        if (userNotifications.data) {
            setNotifications(userNotifications.data);
            if (initialTotal.current === 0) {
                initialTotal.current = userNotifications.pagination.total;
            } else if (initialTotal.current > 0) {
                setTotalNotificationsCount(userNotifications.pagination.total);
                const countDiff =
                    userNotifications.pagination.total - initialTotal.current;
                if (countDiff > 0) {
                    setNotificationsCount(countDiff);
                }
            }
        }
    }

    function generateDemoNotifications() {
        const generateRandomWithinRange = (min, max) =>
            Math.floor(Math.random() * (max - min + 1) + min);

        // product data in same format
        const fakeNotifications = {
            data: [],
            pagination: {
                total: 0,
                perPage: 10,
                pageNum: 1,
            },
            count: generateRandomWithinRange(1, 4), // Generate between 1 and 4 notifications
        };
        // Selected entities
        const entityActivities = {
            Auth: ['Login', 'Logout'],
            Pages: ['Visited the Page'],
            Reports: ['Viewed '],
            'Profile Management': ['Updated Profile'],
        };
        //const users = ['Customer Admin', 'Customer'];
        const entities = Object.keys(entityActivities);
        const numberOfEntities = entities.length;
        for (
            let notfIndex = 0;
            notfIndex < fakeNotifications.count;
            notfIndex++
        ) {
            const randomEntity =
                entities[generateRandomWithinRange(0, numberOfEntities - 1)];
            const entityOperation =
                entityActivities[randomEntity][
                    generateRandomWithinRange(
                        0,
                        entityActivities[randomEntity].length - 1
                    )
                ];
            fakeNotifications['data'][notfIndex] = {
                entity: randomEntity,
                user: `Customer ${generateRandomWithinRange(1, 5)}`,
                description: entityOperation,
                time: new Date(),
            };
        }

        fakeNotifications['pagination']['total'] += fakeNotifications.count;

        setNotifications(fakeNotifications.data);
        sessionStorage.setItem(
            'fakeNotifications',
            JSON.stringify({ fakeNotifications })
        );
        if (initialTotal.current === 0) {
            initialTotal.current = fakeNotifications.pagination.total;
        } else if (initialTotal.current > 0) {
            setTotalNotificationsCount(fakeNotifications.pagination.total);
            const countDiff =
                fakeNotifications.pagination.total - initialTotal.current;
            if (countDiff > 0) {
                setNotificationsCount(countDiff);
            }
        }
    }

    async function getNotifications() {
        const requestUrl = '/api/v1/logger';
        API.PendingRequestsManager.registerRequest(
            component,
            'getNotifications',
            requestUrl
        );
        const logger = await API.get(requestUrl);
        return logger;
    }

    // async function downloadlogbook(){
    // 	const requestUrl = `/api/v1/download-logbook?locations=${user_details?.location?.locationID}`;
    // 	API.PendingRequestsManager.registerRequest(component, 'downloadlogbook', requestUrl);
    // 	const download = await API.get(requestUrl);
    // 	return download;
    // }
    async function checkDownloadLogbookStatus(requestID, intervalReq) {
        return API.get(`/api/v1/download-logbook/status/${requestID}`).then(
            (resStatus) => {
                if (resStatus.isCompleted) {
                    clearInterval(intervalReq);
                    if (!resStatus.hasError) {
                        API.get(
                            `/api/v1/download-logbook/zip/${resStatus.requestID}`
                        )
                            .then((response) => {
                                if (!response.error) {
                                    const url =
                                        window.URL.createObjectURL(response);
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute(
                                        'download',
                                        `${resStatus.locationCode}-${resStatus.timeString}.zip`
                                    );
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                } else {
                                    showSnackbar('error', response.message);
                                }
                                set_download_lb_message(false);
                                set_download_progress(0);
                            })
                            .catch((error) => {
                                showSnackbar(
                                    'error',
                                    `Something went wrong, please try later`
                                );
                                set_download_lb_message(false);
                                set_download_progress(0);
                            });
                    } else {
                        set_download_lb_message(false);
                        set_download_progress(0);
                        showSnackbar('error', resStatus.description);
                    }
                } else {
                    set_download_progress(resStatus.progress);
                }
            }
        );
    }

    async function onClickDownloadLogbook() {
        showSnackbar(
            'info',
            `Your file is processing and will download automatically when ready, Please wait and do not refresh the page or close this window. Thanks`
        );
        set_download_lb_message(true);

        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

        const startDownloadRequest = await API.get(
            `/api/v1/download-logbook/request/${
                user_details?.location?.locationID
            }?startDate=${moment()
                .subtract(13, 'months')
                .format('MM-DD-YYYY')}&endDate=${moment().format(
                'MM-DD-YYYY'
            )}&tz=${timeZone}`
        );

        if (startDownloadRequest.error) {
            showSnackbar('error', startDownloadRequest.message);
            set_download_lb_message(false);
        } else {
            const intervalReq = setInterval(() => {
                checkDownloadLogbookStatus(
                    startDownloadRequest.requestID,
                    intervalReq
                );
            }, 2000);
        }
    }

    function onClickSyncLogbook() {
        showSnackbar('info', `Your location will be synced shortly. Thanks`);
        set_sync_lb_message(true);
        API.get(`/api/v1/location/${user_details?.location?.locationID}/sync`)
            .then((response) => {
                if (!response.error) {
                    // const url = window.URL.createObjectURL(response);
                    // const link = document.createElement('a')
                    // link.href = url
                    // document.body.appendChild(link)
                    // link.click();
                    // document.body.removeChild(link);
                    showSnackbar('success', response.message);
                } else {
                    showSnackbar('error', response.message);
                }
                set_sync_lb_message(false);
            })
            .catch((error) => {
                showSnackbar('error', `Something went wrong, please try later`);
            });
    }

    /* EVENT FUNCTIONS
		-------------------------------------------------------------------------------------*/
    function handleUserMenuOpen(event) {
        set_user_menu_elem(event.currentTarget);
    }
    function handleMobileMenuClose() {
        set_mobile_moreEl(null);
    }

    function handleMenuClose() {
        set_user_menu_elem(null);
        handleMobileMenuClose();
    }

    function handleNotificationMenuOpen(event) {
        set_notification_el(event.currentTarget);
        resetNotificationsCount();
    }

    function resetNotificationsCount() {
        markActivitiesAsRead();
        setNotificationsCount(0);
        setPrevNotificationsCount(notificationsCount);
        initialTotal.current = totalNotificationsCount;
    }

    function showSnackbar(variant, message) {
        enqueueSnackbar(message, {
            variant: variant,
            resumeHideDuration: 5000,
            autoHideDuration: 25000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            TransitionComponent: Slide,
        });
    }

    function gobacktologin() {
        API.get('/api/v1/auth/logout').then((res) => {
            setIsAuth(false);
            setIsActiveSession(false);
            setUserDetails(null);
            setPrimaryUser(null);
            localStorage.clear();
            sessionStorage.clear();
            history('/');
            set_loading_data(false);
        });
    }
    /* RENDER APP
		-------------------------------------------------------------------------------------*/
    return (
        <Box sx={{ flexGrow: 1, zIndex: 9999, mb: { xs: '30px', md: '0' } }}>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: {
                        xs: 'primary.main',
                        lg: 'secondary.main',
                    },
                }}
                color="secondary"
            >
                <Toolbar sx={{ alignItems: 'center', px: '0 !important' }}>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{
                            ml: '0',
                            mr: { xs: 0, md: 2 },
                            display: { lg: 'none' },
                            ...(location.pathname.includes('edit-logbook') && {
                                display: { xs: 'none' },
                            }),
                        }}
                        onClick={drawerToggle}
                    >
                        <MenuIcon sx={{ fontSize: '24px' }} />
                    </IconButton>
                    <Box
                        onClick={() =>
                            history(
                                user_details?.isCustomer &&
                                    !user_details?.isServiceBinder
                                    ? `/client/${user_details?.location?.code}`
                                    : user_details?.isCustomer &&
                                        user_details?.isServiceBinder
                                      ? `/service-binder/${user_details?.location?.friendlyURL}`
                                      : user_details?.isServiceBinder
                                        ? `/service-binder/${user_details?.location?.friendlyURL}`
                                        : '/admin'
                            )
                        }
                        sx={{
                            cursor: 'pointer',
                            height: { xs: '56px', sm: '64px' },
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxSizing: 'border-box',
                            boxShadow: 'inset 0 -1px rgba(0, 0, 0, 0.12)',
                            borderBottom: {
                                lg: '1px solid rgba(255, 255, 255, 0.12)',
                            },
                            width: { lg: '240px' },
                            backgroundColor: { lg: blueGrey800 },
                            '& > img': {
                                maxWidth: { xs: '90px', md: '140px' },
                                '@media (max-width: 360px)': {
                                    maxWidth: '72px',
                                },
                            },
                        }}
                    >
                        {!isImpersonate &&
                            !(
                                user_details?.isServiceBinder &&
                                user_details?.location?.isAdvanceViewActive
                            ) && (
                                <img src={Logo} alt="Sprague Online Logbook" />
                            )}
                        {(isImpersonate ||
                            (user_details?.isServiceBinder &&
                                user_details?.location
                                    ?.isAdvanceViewActive)) && (
                            <img
                                style={{
                                    ...(location.pathname.includes(
                                        'edit-logbook'
                                    ) && {
                                        paddingLeft: '16px',
                                    }),
                                }}
                                class="sprague-logo"
                                src={Logo}
                                alt="Sprague Online Logbook"
                            />
                        )}
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            pl: { xs: '8px', sm: '24px' },
                        }}
                    >
                        <Box>
                            <Typography
                                variant="h3"
                                sx={{
                                    color: 'rgba(255, 255, 255, 0.85);',
                                    display: { xs: 'none', md: 'block' },
                                    fontSize: { xs: '16px', md: '20px' },
                                }}
                            >
                                Welcome to your{' '}
                                {var_service_binder_path ||
                                (!location.pathname.includes('admin') &&
                                    user_details.isServiceBinder) ||
                                (user_details.isServiceBinder &&
                                    user_details.isImpersonate)
                                    ? 'Online Service Binder'
                                    : 'Online Logbook'}
                            </Typography>

                            {!location.pathname.includes('admin') &&
                                user_details?.location && (
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: 'rgba(255, 255, 255, 1)',
                                            whiteSpace: {
                                                xs: 'break-spaces',
                                                md: 'nowrap',
                                            },
                                        }}
                                    >
                                        <Box
                                            component={'span'}
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'inline',
                                                },
                                            }}
                                        ></Box>
                                        {user_details?.location?.name} -{' '}
                                        {user_details?.location?.code}
                                        {user_details?.location?.isOrganic && (
                                            <Box
                                                sx={{ ml: '4px' }}
                                                component={'img'}
                                                src="/images/leaf-white.png"
                                            />
                                        )}
                                    </Typography>
                                )}
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'block' },
                        }}
                    />

                    <Box
                        component="ul"
                        sx={{
                            display: 'flex',
                            listStyle: 'none',
                            p: 0,
                            m: 0,
                            ml: 'auto',
                            '& > li': {
                                borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                                boxShadow:
                                    'inset 1px 0 rgba(255, 255, 255, 0.12)',
                            },
                        }}
                    >
                        {show_logbook_account_link &&
                            !user_details?.isServiceBinder && (
                                <Box
                                    component="li"
                                    sx={{ display: { xs: 'none', lg: 'flex' } }}
                                >
                                    <Button
                                        sx={{ height: '100%', px: 2 }}
                                        color="inherit"
                                        href="https://lb.spraguepest.com/client/1000000"
                                        target="_blank"
                                    >
                                        Logbook
                                    </Button>
                                </Box>
                            )}
                        {show_service_binder_link &&
                            !user_details?.isServiceBinder && (
                                <Box
                                    component="li"
                                    sx={{ display: { xs: 'none', lg: 'flex' } }}
                                >
                                    <Button
                                        sx={{ height: '100%', px: 2 }}
                                        color="inherit"
                                        href="https://service.spraguepest.com/account/login"
                                        target="_blank"
                                    >
                                        Service Binder
                                    </Button>
                                </Box>
                            )}
                        {/* //last sync */}
                        {!location.pathname.includes('admin') &&
                            user_details?.isCustomer &&
                            !user_details?.isServiceBinder &&
                            !(user_details?.location?.code === '100000000') && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: { xs: 'none', md: 'block' },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            color: 'rgba(255, 255, 255, 0.75)',
                                            textAlign: 'right',
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            height: '100%',
                                            px: 2,
                                        }}
                                    >
                                        <Box>Last Sync</Box>
                                        <Tooltip
                                            title={`Updated on: ${
                                                var_last_sync_date
                                                    ? `${moment
                                                          .utc(
                                                              var_last_sync_date
                                                          )
                                                          .local()
                                                          .format(
                                                              'MMM D YYYY hh:mm A'
                                                          )} ${MomentTZ.tz(
                                                          MomentTZ.tz.guess()
                                                      ).zoneName()}`
                                                    : 'No Synced Details'
                                            }`}
                                            arrow
                                        >
                                            <Box
                                                sx={{
                                                    fontSize: '12px',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                <Box>
                                                    {var_last_sync_date
                                                        ? `${moment
                                                              .utc(
                                                                  var_last_sync_date
                                                              )
                                                              .local()
                                                              .format(
                                                                  'MMM D YYYY hh:mm A z'
                                                              )} ${MomentTZ.tz(
                                                              MomentTZ.tz.guess()
                                                          ).zoneName()}`
                                                        : 'No Synced Details'}
                                                </Box>
                                            </Box>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            )}
                        {!location.pathname.includes('admin') &&
                            user_details.isServiceBinder &&
                            user_details.isImpersonate &&
                            !(user_details?.location?.code === '200000000') && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: { xs: 'none', md: 'block' },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            color: 'rgba(255, 255, 255, 0.75)',
                                            textAlign: 'right',
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            height: '100%',
                                            px: 2,
                                        }}
                                    >
                                        <Box>Last Sync</Box>
                                        <Tooltip
                                            title={`Updated on: ${
                                                var_last_sync_date
                                                    ? `${moment
                                                          .utc(
                                                              var_last_sync_date
                                                          )
                                                          .local()
                                                          .format(
                                                              'MMM D YYYY hh:mm A z'
                                                          )} ${MomentTZ.tz(
                                                          MomentTZ.tz.guess()
                                                      ).zoneName()}`
                                                    : 'Not Found'
                                            }`}
                                            arrow
                                        >
                                            <Box
                                                sx={{
                                                    fontSize: '12px',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                <Box>
                                                    {var_last_sync_date
                                                        ? `${moment
                                                              .utc(
                                                                  var_last_sync_date
                                                              )
                                                              .local()
                                                              .format(
                                                                  'MMM D YYYY hh:mm A z'
                                                              )} ${MomentTZ.tz(
                                                              MomentTZ.tz.guess()
                                                          ).zoneName()}`
                                                        : 'No Synced Details'}
                                                </Box>
                                            </Box>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            )}
                        {/* //end last sync */}

                        {/* //last login */}
                        {!location.pathname.includes('admin') &&
                            user_details?.isCustomer &&
                            !user_details?.isServiceBinder && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: { xs: 'none', md: 'block' },
                                    }}
                                >
                                    <LastLoggedin userMenu={false} />
                                </Box>
                            )}
                        {user_details?.isAdmin &&
                            !user_details?.isCustomer &&
                            !user_details?.isServiceBinder && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: { xs: 'none', md: 'block' },
                                    }}
                                >
                                    <LastLoggedin userMenu={false} />
                                </Box>
                            )}
                        {location.pathname.includes('admin') &&
                            primary_user?.isAdmin &&
                            user_details.isImpersonate && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: { xs: 'none', md: 'block' },
                                    }}
                                >
                                    <LastLoggedin userMenu={false} />
                                </Box>
                            )}
                        {user_details?.isFieldUser &&
                            !user_details?.isAdmin &&
                            !user_details?.isCustomer && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: { xs: 'none', md: 'block' },
                                    }}
                                >
                                    <LastLoggedin userMenu={false} />
                                </Box>
                            )}
                        {location.pathname.includes('admin') &&
                            primary_user?.isFieldUser &&
                            user_details.isImpersonate && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: { xs: 'none', md: 'block' },
                                    }}
                                >
                                    <LastLoggedin userMenu={false} />
                                </Box>
                            )}
                        {user_details?.isContentManager &&
                            !user_details?.isAdmin &&
                            !user_details?.isCustomer && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: { xs: 'none', md: 'block' },
                                    }}
                                >
                                    <LastLoggedin userMenu={false} />
                                </Box>
                            )}
                        {location.pathname.includes('admin') &&
                            primary_user?.isContentManager &&
                            user_details.isImpersonate && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: { xs: 'none', md: 'block' },
                                    }}
                                >
                                    <LastLoggedin userMenu={false} />
                                </Box>
                            )}

                        {user_details.isServiceBinder ||
                        user_details.isCustomer ? (
                            <></>
                        ) : (
                            <Box
                                component="li"
                                sx={{ display: 'flex', alignItems: 'center' }}
                            >
                                <IconButton
                                    color="inherit"
                                    sx={{ height: '100%', borderRadius: '0' }}
                                    onClick={openAlerts}
                                >
                                    <NotificationsActiveIcon />
                                </IconButton>
                            </Box>
                        )}
                        {/* //end last login */}

                        {/* //pest program heading */}
                        {!location.pathname.includes('admin') &&
                            user_details?.isCustomer &&
                            !user_details?.isServiceBinder && (
                                <>
                                    <Box
                                        component="li"
                                        sx={{
                                            display: { xs: 'none', sm: 'flex' },
                                        }}
                                    >
                                        <PestProgramLogbook />
                                    </Box>

                                    <Box
                                        component="li"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Tooltip
                                            title={
                                                var_download_lb_message ? (
                                                    <React.Fragment>
                                                        {/* <Box component="div" display="flex">
											<CircularProgress
												variant="determinate"
													value={100}
													color="primary"
													sx={{
														position: 'absolute',
														top: '50%',
														left: '50%',
														marginTop: '-20px',
														marginLeft: '-20px',
													}}
												/>
												<Box
												sx={{
												  top: 0,
												  left: 0,
												  bottom: 0,
												  right: 0,
												  position: 'absolute',
												  display: 'flex',
												  alignItems: 'center',
												  justifyContent: 'center',
												}}
											  >
												<Typography variant="caption" component="div" sx={{fontSize:'16px',textAlign:'center',}} >
												  {`${Math.round(var_downloadProgress)}%`}
												</Typography>
											  </Box>
											  <Box component="div" margin={"90px 0px 25px 0px"}><Typography variant='h5' >Logbook download is in-progress...</Typography></Box>
										</Box> */}
                                                        <Box
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                        >
                                                            <Box
                                                                sx={{
                                                                    position:
                                                                        'relative',
                                                                    display:
                                                                        'inline-flex',
                                                                }}
                                                            >
                                                                <CircularProgress
                                                                    variant="determinate"
                                                                    value={
                                                                        var_download_progress
                                                                    }
                                                                    color="primary"
                                                                />
                                                                <Box
                                                                    sx={{
                                                                        top: 0,
                                                                        left: 0,
                                                                        bottom: 0,
                                                                        right: 0,
                                                                        position:
                                                                            'absolute',
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                        justifyContent:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant="caption"
                                                                        component="div"
                                                                        color="text.secondary"
                                                                    >
                                                                        {`${Math.round(
                                                                            var_download_progress
                                                                        )}%`}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>

                                                        <Box component="div">
                                                            <Typography variant="h5">
                                                                Logbook download
                                                                is in
                                                                progress...
                                                            </Typography>
                                                        </Box>
                                                    </React.Fragment>
                                                ) : (
                                                    'Download Logbook'
                                                )
                                            }
                                            arrow
                                        >
                                            <span>
                                                <IconButton
                                                    disabled={
                                                        var_download_lb_message
                                                    }
                                                    sx={{ width: '48px' }}
                                                    color="inherit"
                                                    size="large"
                                                    tool
                                                    onClick={
                                                        onClickDownloadLogbook
                                                    }
                                                >
                                                    {!var_download_lb_message && (
                                                        <CloudDownloadIcon />
                                                    )}
                                                    {/* <CloudDownloadIcon /> */}
                                                    {var_download_lb_message && (
                                                        <>
                                                            <CircularProgress
                                                                size={32}
                                                                // value={var_downloadProgress}
                                                                sx={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    marginTop:
                                                                        '-16px',
                                                                    marginLeft:
                                                                        '-24px',
                                                                    padding:
                                                                        '0 8px',
                                                                }}
                                                            />
                                                            <Box
                                                                sx={{
                                                                    top: 0,
                                                                    left: 0,
                                                                    bottom: 0,
                                                                    right: 0,
                                                                    position:
                                                                        'absolute',
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    justifyContent:
                                                                        'center',
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="caption"
                                                                    component="div"
                                                                    sx={{
                                                                        color: '#FFFFFF',
                                                                    }}
                                                                >
                                                                    {`${Math.round(
                                                                        var_download_progress
                                                                    )}%`}
                                                                </Typography>
                                                            </Box>
                                                        </>
                                                    )}
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </Box>
                                </>
                            )}
                        {/* //end pest program heading */}
                        {/* //last sync icon */}
                        {!location.pathname.includes('admin') &&
                            user_details?.isServiceBinder &&
                            user_details.isImpersonate &&
                            !(user_details?.location?.code === '200000000') && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Tooltip title="Sync Service Binder" arrow>
                                        <IconButton
                                            disabled={var_sync_lb_message}
                                            color="inherit"
                                            size="large"
                                            onClick={onClickSyncLogbook}
                                        >
                                            <AutorenewIcon />
                                            {var_sync_lb_message && (
                                                <CircularProgress
                                                    size={32}
                                                    color="inherit"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-16px',
                                                        marginLeft: '-16px',
                                                    }}
                                                />
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            )}
                        {!location.pathname.includes('admin') &&
                            user_details?.isCustomer &&
                            !user_details?.isServiceBinder &&
                            !(user_details?.location?.code === '100000000') && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Tooltip title="Sync Logbook" arrow>
                                        <IconButton
                                            disabled={var_sync_lb_message}
                                            color="inherit"
                                            size="large"
                                            onClick={onClickSyncLogbook}
                                        >
                                            <AutorenewIcon />
                                            {var_sync_lb_message && (
                                                <CircularProgress
                                                    size={32}
                                                    color="inherit"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-16px',
                                                        marginLeft: '-16px',
                                                    }}
                                                />
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            )}
                        {/* //end last sync icon */}
                        {/* //New logbook */}
                        {user_details?.isAdmin &&
                            !user_details?.isServiceBinder &&
                            !(user_details?.location?.code === '100000000') && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Button
                                        variant="text"
                                        color="inherit"
                                        startIcon={<AddIcon />}
                                        sx={{
                                            height: '100%',
                                            borderRadius: '0',
                                        }}
                                        onClick={openMdlCreateLogbook}
                                    >
                                        New Logbook
                                    </Button>
                                </Box>
                            )}
                        {location.pathname.includes('admin') &&
                            primary_user?.isAdmin &&
                            user_details?.isImpersonate && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Button
                                        variant="text"
                                        color="inherit"
                                        startIcon={<AddIcon />}
                                        sx={{
                                            height: '100%',
                                            borderRadius: '0',
                                        }}
                                        onClick={openMdlCreateLogbook}
                                    >
                                        New Logbook
                                    </Button>
                                </Box>
                            )}
                        {user_details?.isContentManager &&
                            !user_details?.isAdmin &&
                            !user_details?.isCustomer &&
                            !user_details?.isServiceBinder && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Button
                                        variant="text"
                                        color="inherit"
                                        startIcon={<AddIcon />}
                                        sx={{
                                            height: '100%',
                                            borderRadius: '0',
                                        }}
                                        onClick={openMdlCreateLogbook}
                                    >
                                        New Logbook
                                    </Button>
                                </Box>
                            )}
                        {location.pathname.includes('admin') &&
                            primary_user?.isContentManager &&
                            user_details?.isImpersonate && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Button
                                        variant="text"
                                        color="inherit"
                                        startIcon={<AddIcon />}
                                        sx={{
                                            height: '100%',
                                            borderRadius: '0',
                                        }}
                                        onClick={openMdlCreateLogbook}
                                    >
                                        New Logbook
                                    </Button>
                                </Box>
                            )}
                        {/* //end New logbook */}
                        {/* //Advance view */}
                        {user_details?.isServiceBinder &&
                            !user_details?.isImpersonate && (
                                <>
                                    <Box
                                        component="li"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Button
                                            variant="text"
                                            color="inherit"
                                            sx={{
                                                height: '100%',
                                                borderRadius: '0',
                                            }}
                                            onClick={gobacktologin}
                                        >
                                            Back to Login
                                        </Button>
                                    </Box>
                                    {user_details?.location
                                        ?.isAdvanceViewActive && (
                                        <Box
                                            component="li"
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color={
                                                    !isMobile
                                                        ? 'primary'
                                                        : 'secondary'
                                                }
                                                disabled={
                                                    user_details?.location
                                                        ?.code === '200000000'
                                                        ? true
                                                        : false
                                                }
                                                onClick={openMdlAdvView}
                                                sx={{ mx: '16px' }}
                                            >
                                                Advance View
                                            </Button>
                                        </Box>
                                    )}
                                </>
                            )}
                        {/* //end Advance view */}
                        {/* //Account icon */}
                        {user_details?.isAdmin &&
                            !user_details?.isServiceBinder && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: { xs: 'flex', lg: 'flex' },
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconButton
                                        onClick={handleUserMenuOpen}
                                        color="inherit"
                                        size="large"
                                    >
                                        <AccountCircleIcon />
                                    </IconButton>
                                </Box>
                            )}
                        {!location.pathname.includes('admin') &&
                            user_details?.isCustomer &&
                            !user_details?.isServiceBinder && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: { xs: 'flex', lg: 'flex' },
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconButton
                                        onClick={handleUserMenuOpen}
                                        color="inherit"
                                        size="large"
                                    >
                                        <AccountCircleIcon />
                                    </IconButton>
                                </Box>
                            )}
                        {location.pathname.includes('admin') &&
                            primary_user?.isAdmin &&
                            user_details?.isImpersonate && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: { xs: 'flex', lg: 'flex' },
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconButton
                                        onClick={handleUserMenuOpen}
                                        color="inherit"
                                        size="large"
                                    >
                                        <AccountCircleIcon />
                                    </IconButton>
                                </Box>
                            )}
                        {user_details?.isContentManager &&
                            !user_details?.isAdmin &&
                            !user_details?.isCustomer &&
                            !user_details?.isServiceBinder && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: { xs: 'flex', lg: 'flex' },
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconButton
                                        onClick={handleUserMenuOpen}
                                        color="inherit"
                                        size="large"
                                    >
                                        <AccountCircleIcon />
                                    </IconButton>
                                </Box>
                            )}
                        {location.pathname.includes('admin') &&
                            primary_user?.isContentManager &&
                            user_details?.isImpersonate && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: { xs: 'flex', lg: 'flex' },
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconButton
                                        onClick={handleUserMenuOpen}
                                        color="inherit"
                                        size="large"
                                    >
                                        <AccountCircleIcon />
                                    </IconButton>
                                </Box>
                            )}
                        {user_details?.isFieldUser &&
                            !user_details?.isAdmin &&
                            !user_details?.isCustomer &&
                            !user_details?.isServiceBinder && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: { xs: 'flex', lg: 'flex' },
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconButton
                                        onClick={handleUserMenuOpen}
                                        color="inherit"
                                        size="large"
                                    >
                                        <AccountCircleIcon />
                                    </IconButton>
                                </Box>
                            )}
                        {location.pathname.includes('admin') &&
                            primary_user?.isFieldUser &&
                            user_details?.isImpersonate && (
                                <Box
                                    component="li"
                                    sx={{
                                        display: { xs: 'flex', lg: 'flex' },
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconButton
                                        onClick={handleUserMenuOpen}
                                        color="inherit"
                                        size="large"
                                    >
                                        <AccountCircleIcon />
                                    </IconButton>
                                </Box>
                            )}
                        {/* //end Account icon */}
                    </Box>
                </Toolbar>
            </AppBar>

            <MobileMenu
                anchorEl={var_mobile_moreEl}
                handleMenuOpen={isMobileMenuOpen}
                handleMobileMenuClose={handleMobileMenuClose}
                handleUserMenuOpen={handleUserMenuOpen}
                handleNotificationMenuOpen={handleNotificationMenuOpen}
                notificationsList={notifications}
            />

            <UserMenu
                anchorEl={var_user_menu_elem}
                handleMenuOpen={isMenuOpen}
                handleMenuClose={handleMenuClose}
                openMdlPreferences={openMdlPreferences}
            />
        </Box>
    );
}

AppTopBar.defaultProps = {
    drawerToggle: () => {},
    openMdlNotifications: () => {},
    closeMdlNotifications: () => {},
};

AppTopBar.propTypes = {
    drawerToggle: PropTypes.func,
    openMdlNotifications: PropTypes.func,
    closeMdlNotifications: PropTypes.func,
};

export default AppTopBar;
