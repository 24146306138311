import React from 'react';
import moment from 'moment';
import { Box } from '@mui/material';

function PestProgramLogbook() {
    return (
        <Box
            sx={{
                color: 'rgba(255, 255, 255, 0.75)',
                textAlign: 'right',
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
                px: 2,
            }}
        >
            <Box>Pest Program Logbook</Box>
            <Box sx={{ fontSize: '12px', whiteSpace: 'nowrap', mt: '4px' }}>
                {moment().subtract(13, 'months').format('DD MMM YYYY')} -{' '}
                {moment().format('DD MMM YYYY')}
            </Box>
        </Box>
    );
}

export default PestProgramLogbook;
