import React, { useEffect } from 'react';
import {
    NavLink,
    useMatch,
    useLocation,
    useSearchParams,
} from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArticleIcon from '@mui/icons-material/Article';
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';
import OpacityIcon from '@mui/icons-material/Opacity';
import SecurityIcon from '@mui/icons-material/Security';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import RoofingIcon from '@mui/icons-material/Roofing';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DescriptionIcon from '@mui/icons-material/Description';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import SettingsIcon from '@mui/icons-material/Settings';
import { useData } from '../../Context/Context';
import {
    StyledList,
    StyledLink,
    StyledIcon,
    StyledText,
    StyledListItem,
} from './styles';
import { Analytics } from '@mui/icons-material';
import { useState } from 'react';

function DrawerList() {
    /* VARIABLE DECLARATION
    -------------------------------------------------------------------------------------*/
    const { globalIsDisabled } = useData(useData);
    let match_service_binder = useMatch({
        path: '/service-binder/:sId',
        end: false,
    });
    let match_adv_view = useMatch({
        path: '/admin/advance-view/:advID',
        end: false,
    });
    const location = useLocation();
    const { userData, primaryUser } = useData(useData);
    const [primary_user] = primaryUser;
    const [user_details] = userData;
    const [global_isDisabled] = globalIsDisabled;
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token'); // "testCode"
    let sbinderId = match_service_binder?.params.sId
        ? match_service_binder?.params.sId
        : user_details?.location?.friendlyURL;
    const [isImpersonate, set_isImpersonate] = useState('');
    const [primaryuser, set_primary_user] = useState('');
    // const [primary_user, set_primaryUser] = primaryUser;
    const [isServiceBinder, set_isServiceBinder] = useState('');
    const [impersonatingRole, set_impersonatingRole] = useState('');

    let locationCode =
        sessionStorage.getItem('locationCode') ||
        user_details.location?.locationID;

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/

    useEffect(() => {
        setTimeout(() => {
            if (location.pathname.includes('admin')) {
                sessionStorage.removeItem('isImpersonate');
                sessionStorage.removeItem('primaryUser');
                sessionStorage.removeItem('impersonatingRole');
                sessionStorage.removeItem('isServiceBinder');
                sessionStorage.removeItem('locationCode');
            }
            set_isImpersonate(
                sessionStorage.getItem('isImpersonate') === 'true'
            );
            set_impersonatingRole(sessionStorage.getItem('impersonatingRole'));
            set_primary_user(sessionStorage.getItem('primaryUser'));
            set_isServiceBinder(
                sessionStorage.getItem('isServiceBinder') === 'true'
            );

            window.addEventListener('storage', storageEventHandler);
        }, 1000);

        function storageEventHandler() {
            set_isImpersonate(
                sessionStorage.getItem('isImpersonate') === 'true'
            );
            set_primary_user(sessionStorage.getItem('primaryUser'));
            set_impersonatingRole(sessionStorage.getItem('impersonatingRole'));
            set_isServiceBinder(
                sessionStorage.getItem('isServiceBinder') === 'true'
            );

            locationCode =
                sessionStorage.getItem('locationCode') ||
                user_details?.location?.locationID;
        }

        return () => {
            window.removeEventListener('storage', storageEventHandler);
        };
    }, []);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <>
            {!location.pathname.includes('admin/edit-logbook') && (
                <StyledList component="nav">
                    {/* // isAdmin. */}
                    {location.pathname.includes('admin') &&
                        user_details.isAdmin &&
                        !user_details.isCustomer && (
                            <>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        component={NavLink}
                                        to={`/admin`}
                                    >
                                        <StyledIcon>
                                            <DashboardIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Dashboard
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/logbooks`}
                                        end
                                    >
                                        <StyledIcon>
                                            <LibraryBooksIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Logbooks
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/servicebinders`}
                                        isActive={(match, location) => {
                                            return match_adv_view || match;
                                        }}
                                    >
                                        <StyledIcon>
                                            <RoofingIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Service Binders
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        // activeClassName={"Mui-selected"}
                                        component={NavLink}
                                        to={`/admin/documents`}
                                        isActive={(match, location) => {
                                            return (
                                                match ||
                                                location.pathname ===
                                                    '/admin/document/templates'
                                            );
                                        }}
                                    >
                                        <StyledIcon>
                                            <DescriptionIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Documents
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/manage-users`}
                                    >
                                        <StyledIcon>
                                            <GroupAddIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Manage Users
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/pest-sighting`}
                                    >
                                        <StyledIcon>
                                            <ArticleIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Pest Sighting
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/adminsettings`}
                                    >
                                        <StyledIcon>
                                            <SettingsIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Admin Settings
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/report`}
                                    >
                                        <StyledIcon>
                                            <AssessmentIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Report
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/help-tutorial`}
                                    >
                                        <StyledIcon>
                                            <HelpCenterIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Help/Tutorial
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                            </>
                        )}
                    {location.pathname.includes('admin') &&
                        (primaryuser?.toLowerCase() === 'admin' ||
                            primary_user?.role === 'Admin') &&
                        isImpersonate && (
                            <>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        // //activeClassName="Mui-selected"
                                        // activeClassName={"Mui-selected"}
                                        component={NavLink}
                                        to={`/admin`}
                                    >
                                        <StyledIcon>
                                            <DashboardIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Dashboard
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/logbooks`}
                                    >
                                        <StyledIcon>
                                            <LibraryBooksIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Logbooks
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/servicebinders`}
                                        isActive={(match, location) => {
                                            return match_adv_view || match;
                                        }}
                                    >
                                        <StyledIcon>
                                            <RoofingIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Service Binders
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/documents`}
                                        isActive={(match, location) => {
                                            return (
                                                match ||
                                                location.pathname ===
                                                    '/admin/document/templates'
                                            );
                                        }}
                                    >
                                        <StyledIcon>
                                            <DescriptionIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Documents
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/manage-users`}
                                    >
                                        <StyledIcon>
                                            <GroupAddIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Manage Users
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/pest-sighting`}
                                    >
                                        <StyledIcon>
                                            <ArticleIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Pest Sighting
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/help-tutorial`}
                                    >
                                        <StyledIcon>
                                            <HelpCenterIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Help/Tutorial
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/adminsettings`}
                                    >
                                        <StyledIcon>
                                            <HelpCenterIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Admin Settings
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                            </>
                        )}
                    {/* // End isAdmin. */}
                    {/* // isContentManager. */}
                    {location.pathname.includes('admin') &&
                        user_details?.isContentManager &&
                        !user_details?.isFieldUser &&
                        !user_details?.isAdmin &&
                        !user_details?.isCustomer && (
                            <>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin`}
                                    >
                                        <StyledIcon>
                                            <DashboardIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Dashboard
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/logbooks`}
                                    >
                                        <StyledIcon>
                                            <LibraryBooksIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Logbook
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/servicebinders`}
                                    >
                                        <StyledIcon>
                                            <RoofingIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Service Binders
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/documents`}
                                        isActive={(match, location) => {
                                            return (
                                                match ||
                                                location.pathname ===
                                                    '/admin/document/templates'
                                            );
                                        }}
                                    >
                                        <StyledIcon>
                                            <DescriptionIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Documents
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/manage-users`}
                                    >
                                        <StyledIcon>
                                            <GroupAddIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Manage Users
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/pest-sighting`}
                                    >
                                        <StyledIcon>
                                            <ArticleIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Pest Sighting
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/help-tutorial`}
                                    >
                                        <StyledIcon>
                                            <HelpCenterIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Help/Tutorial
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                            </>
                        )}
                    {location.pathname.includes('admin') &&
                        (primaryuser?.toLowerCase() === 'content manager' ||
                            primary_user?.role === 'Content Manager') &&
                        isImpersonate && (
                            <>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin`}
                                    >
                                        <StyledIcon>
                                            <DashboardIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Dashboard
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/logbooks`}
                                    >
                                        <StyledIcon>
                                            <LibraryBooksIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Logbook
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/servicebinders`}
                                    >
                                        <StyledIcon>
                                            <RoofingIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Service Binders
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/documents`}
                                        isActive={(match, location) => {
                                            return (
                                                match ||
                                                location.pathname ===
                                                    '/admin/document/templates'
                                            );
                                        }}
                                    >
                                        <StyledIcon>
                                            <DescriptionIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Documents
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/manage-users`}
                                    >
                                        <StyledIcon>
                                            <GroupAddIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Manage Users
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/pest-sighting`}
                                    >
                                        <StyledIcon>
                                            <ArticleIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Pest Sighting
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/help-tutorial`}
                                    >
                                        <StyledIcon>
                                            <HelpCenterIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Help/Tutorial
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/adminsettings`}
                                    >
                                        <StyledIcon>
                                            <HelpCenterIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Admin Settings
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                            </>
                        )}
                    {/* // End isContentManager. */}
                    {/* // isFieldUser. */}
                    {location.pathname.includes('admin') &&
                        user_details?.isFieldUser &&
                        !user_details?.isAdmin &&
                        !user_details?.isCustomer && (
                            <>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin`}
                                    >
                                        <StyledIcon>
                                            <DashboardIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Dashboard
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/logbooks`}
                                    >
                                        <StyledIcon>
                                            <LibraryBooksIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Logbook
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/servicebinders`}
                                    >
                                        <StyledIcon>
                                            <RoofingIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Service Binders
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                {/* 
                    <StyledListItem>
                        <StyledLink end //activeClassName="Mui-selected" component={NavLink} to={`/admin/documents`}>
                            <StyledIcon><DescriptionIcon /></StyledIcon>
                            <StyledText disableTypography>Documents</StyledText>
                        </StyledLink>
                    </StyledListItem> */}

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/pest-sighting`}
                                    >
                                        <StyledIcon>
                                            <ArticleIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Pest Sighting
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/help-tutorial`}
                                    >
                                        <StyledIcon>
                                            <HelpCenterIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Help/Tutorial
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                            </>
                        )}
                    {location.pathname.includes('admin') &&
                        (primaryuser?.toLowerCase() === 'field user' ||
                            primary_user?.role === 'Field User') &&
                        isImpersonate && (
                            <>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin`}
                                    >
                                        <StyledIcon>
                                            <DashboardIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Dashboard
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/logbooks`}
                                    >
                                        <StyledIcon>
                                            <LibraryBooksIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Logbook
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/servicebinders`}
                                    >
                                        <StyledIcon>
                                            <RoofingIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Service Binders
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                {/* 
                    <StyledListItem>
                        <StyledLink end //activeClassName="Mui-selected" component={NavLink} to={`/admin/documents`}>
                            <StyledIcon><DescriptionIcon /></StyledIcon>
                            <StyledText disableTypography>Documents</StyledText>
                        </StyledLink>
                    </StyledListItem> */}

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/pest-sighting`}
                                    >
                                        <StyledIcon>
                                            <ArticleIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Pest Sighting
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/admin/help-tutorial`}
                                    >
                                        <StyledIcon>
                                            <HelpCenterIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Help/Tutorial
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                            </>
                        )}
                    {/* // End isFieldUser. */}
                    {/* // isCustomer. */}
                    {!location.pathname.includes('admin') &&
                        (user_details.isCustomer ||
                            impersonatingRole?.toLowerCase() === 'customer') &&
                        !user_details.isServiceBinder && (
                            <>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/client/${locationCode}`}
                                    >
                                        <StyledIcon>
                                            <DashboardIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Dashboard
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/client/${locationCode}/reports`}
                                    >
                                        <StyledIcon>
                                            <AssessmentIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Reports
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/client/${locationCode}/programs`}
                                    >
                                        <StyledIcon>
                                            <ListIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Program
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/client/${locationCode}/materials`}
                                    >
                                        <StyledIcon>
                                            <OpacityIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Materials
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/client/${locationCode}/licensing-and-training`}
                                    >
                                        <StyledIcon>
                                            <SecurityIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Licensing &amp; Training
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/client/${locationCode}/maps`}
                                    >
                                        <StyledIcon>
                                            <MapIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Maps
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/client/${locationCode}/pest-sighting`}
                                    >
                                        <StyledIcon>
                                            <ArticleIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Pest Sighting
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/client/${locationCode}/help-tutorial`}
                                    >
                                        <StyledIcon>
                                            <HelpCenterIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Help/Tutorial
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                            </>
                        )}
                    {/* // End isCustomer. */}
                    {/* // isServiceBinder. */}
                    {/* //Service binder for without login */}
                    {!location.pathname.includes('admin') &&
                        !location.pathname.includes('link-expired') &&
                        (user_details?.isServiceBinder || isServiceBinder) &&
                        !user_details?.isFieldUser &&
                        !user_details?.isAdmin &&
                        (user_details?.isCustomer ||
                            impersonatingRole?.toLowerCase() === 'customer') &&
                        primaryuser === null &&
                        primary_user === null && (
                            <>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <DashboardIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Dashboard
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/service-records${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <ListIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Service Records
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'CONDITION REPORT'
                                    ) &&
                                    token && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/conditions-report${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details?.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <DescriptionIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Condition Report
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}
                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'INSPECTION REPORT'
                                    ) &&
                                    token && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/inspection-report${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details?.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <DescriptionIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Inspection Report
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}
                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'DEVICE TRENDING REPORT'
                                    ) &&
                                    token && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/device-trending-report${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details?.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <DescriptionIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Device Trending
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}
                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'PEST TRENDING REPORT'
                                    ) &&
                                    token && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/pest-trending-report${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details?.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <DescriptionIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Pest Trending
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/pest-management${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <Analytics />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Pest Management Program
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/sds-labels${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <DescriptionIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            SDS & Labels
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/documents-license${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <SecurityIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Licenses & Certifications
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'MAPS'
                                    ) &&
                                    token && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/maps${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details?.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <MapIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Maps
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}

                                {user_details?.location
                                    ?.isPestSightingActive && (
                                    <StyledListItem>
                                        <StyledLink
                                            end
                                            disabled={global_isDisabled}
                                            //activeClassName="Mui-selected"
                                            component={NavLink}
                                            to={`/service-binder/${sbinderId}/pest-sighting${
                                                user_details?.token
                                                    ? '?token=' +
                                                      user_details?.token
                                                    : ''
                                            }`}
                                        >
                                            <StyledIcon>
                                                <ArticleIcon />
                                            </StyledIcon>
                                            <StyledText disableTypography>
                                                Pest Sighting
                                            </StyledText>
                                        </StyledLink>
                                    </StyledListItem>
                                )}
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/help-tutorial${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <HelpCenterIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Help/Tutorial
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                            </>
                        )}
                    {/* //Service binder for Field user */}
                    {!location.pathname.includes('admin') &&
                        (user_details?.isServiceBinder || isServiceBinder) &&
                        !(
                            user_details?.isCustomer ||
                            impersonatingRole?.toLowerCase() === 'customer'
                        ) &&
                        !(
                            user_details?.isAdmin ||
                            impersonatingRole?.toLowerCase() === 'admin'
                        ) &&
                        isImpersonate &&
                        token && (
                            <>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <DashboardIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Dashboard
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/service-records${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <ListIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Service Records
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'CONDITION REPORT'
                                    ) &&
                                    token && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/conditions-report${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details?.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <DescriptionIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Condition Report
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}
                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'INSPECTION REPORT'
                                    ) &&
                                    token && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/inspection-report${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details?.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <DescriptionIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Inspection Report
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}
                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'DEVICE TRENDING REPORT'
                                    ) &&
                                    token && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/device-trending-report${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details?.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <DescriptionIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Device Trending
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}
                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'PEST TRENDING REPORT'
                                    ) &&
                                    token && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/pest-trending-report${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details?.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <DescriptionIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Pest Trending
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/pest-management${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <Analytics />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Pest Management Program
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/sds-labels${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <DescriptionIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            SDS & Labels
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/documents-license${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <SecurityIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Licenses & Certifications
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'MAPS'
                                    ) &&
                                    token && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/maps${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details?.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <MapIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Maps
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}

                                {user_details?.location
                                    ?.isPestSightingActive && (
                                    <StyledListItem>
                                        <StyledLink
                                            end
                                            disabled={global_isDisabled}
                                            //activeClassName="Mui-selected"
                                            component={NavLink}
                                            to={`/service-binder/${sbinderId}/pest-sighting${
                                                user_details?.token
                                                    ? '?token=' +
                                                      user_details?.token
                                                    : ''
                                            }`}
                                        >
                                            <StyledIcon>
                                                <ArticleIcon />
                                            </StyledIcon>
                                            <StyledText disableTypography>
                                                Pest Sighting
                                            </StyledText>
                                        </StyledLink>
                                    </StyledListItem>
                                )}
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/help-tutorial${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <HelpCenterIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Help/Tutorial
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                            </>
                        )}
                    {!location.pathname.includes('admin') &&
                        (user_details?.isServiceBinder || isServiceBinder) &&
                        user_details?.isFieldUser &&
                        !user_details?.isAdmin &&
                        (user_details?.isCustomer ||
                            impersonatingRole?.toLowerCase() ===
                                'customer') && (
                            <>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <DashboardIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Dashboard
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/service-records${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <ListIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Service Records
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'CONDITION REPORT'
                                    ) &&
                                    token && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/conditions-report${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details?.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <DescriptionIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Condition Report
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}
                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'INSPECTION REPORT'
                                    ) &&
                                    token && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/inspection-report${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <DescriptionIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Inspection Report
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}
                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'DEVICE TRENDING REPORT'
                                    ) &&
                                    token && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/device-trending-report${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details?.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <DescriptionIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Device Trending
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}
                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'PEST TRENDING REPORT'
                                    ) &&
                                    token && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/pest-trending-report${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details?.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <DescriptionIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Pest Trending
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/pest-management${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <Analytics />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Pest Management Program
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/sds-labels${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <DescriptionIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            SDS & Labels
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/documents-license${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <SecurityIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Licenses & Certifications
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'MAPS'
                                    ) &&
                                    token && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/maps${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details?.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <MapIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Maps
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}
                                {user_details?.location
                                    ?.isPestSightingActive && (
                                    <StyledListItem>
                                        <StyledLink
                                            end
                                            disabled={global_isDisabled}
                                            //activeClassName="Mui-selected"
                                            component={NavLink}
                                            to={`/service-binder/${sbinderId}/pest-sighting${
                                                user_details?.token
                                                    ? '?token=' +
                                                      user_details?.token
                                                    : ''
                                            }`}
                                        >
                                            <StyledIcon>
                                                <ArticleIcon />
                                            </StyledIcon>
                                            <StyledText disableTypography>
                                                Pest Sighting
                                            </StyledText>
                                        </StyledLink>
                                    </StyledListItem>
                                )}
                            </>
                        )}
                    {/* //Service binder for Admin and content manager */}
                    {!location.pathname.includes('admin') &&
                        (user_details?.isServiceBinder || isServiceBinder) &&
                        (user_details?.isCustomer ||
                            impersonatingRole?.toLowerCase() === 'customer') &&
                        !user_details?.isFieldUser &&
                        !user_details?.isAdmin &&
                        (user_details?.isImpersonate || isImpersonate) && (
                            <>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <DashboardIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Dashboard
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/service-records${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <ListIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Service Records
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'CONDITION REPORT'
                                    ) && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/conditions-report${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <DescriptionIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Condition Report
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}
                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'INSPECTION REPORT'
                                    ) && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/inspection-report${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <DescriptionIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Inspection Report
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}
                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'DEVICE TRENDING REPORT'
                                    ) && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/device-trending-report${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details?.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <DescriptionIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Device Trending
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}
                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'PEST TRENDING REPORT'
                                    ) && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/pest-trending-report${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details?.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <DescriptionIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Pest Trending
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/pest-management${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <Analytics />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Pest Management Program
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/sds-labels${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <DescriptionIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            SDS & Labels
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/documents-license${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <SecurityIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Licenses & Certifications
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>

                                {(user_details?.isadvancestatus ||
                                    user_details?.location
                                        ?.isAdvanceViewActive) &&
                                    user_details?.location?.advanceViewPermissions?.includes(
                                        'MAPS'
                                    ) && (
                                        <StyledListItem>
                                            <StyledLink
                                                end
                                                disabled={global_isDisabled}
                                                //activeClassName="Mui-selected"
                                                component={NavLink}
                                                to={`/service-binder/${sbinderId}/maps${
                                                    user_details?.token
                                                        ? '?token=' +
                                                          user_details.token
                                                        : ''
                                                }`}
                                            >
                                                <StyledIcon>
                                                    <MapIcon />
                                                </StyledIcon>
                                                <StyledText disableTypography>
                                                    Maps
                                                </StyledText>
                                            </StyledLink>
                                        </StyledListItem>
                                    )}

                                {user_details?.location
                                    ?.isPestSightingActive && (
                                    <StyledListItem>
                                        <StyledLink
                                            end
                                            disabled={global_isDisabled}
                                            //activeClassName="Mui-selected"
                                            component={NavLink}
                                            to={`/service-binder/${sbinderId}/pest-sighting${
                                                user_details?.token
                                                    ? '?token=' +
                                                      user_details?.token
                                                    : ''
                                            }`}
                                        >
                                            <StyledIcon>
                                                <ArticleIcon />
                                            </StyledIcon>
                                            <StyledText disableTypography>
                                                Pest Sighting
                                            </StyledText>
                                        </StyledLink>
                                    </StyledListItem>
                                )}
                                <StyledListItem>
                                    <StyledLink
                                        end
                                        disabled={global_isDisabled}
                                        //activeClassName="Mui-selected"
                                        component={NavLink}
                                        to={`/service-binder/${sbinderId}/help-tutorial${
                                            user_details?.token
                                                ? '?token=' +
                                                  user_details?.token
                                                : ''
                                        }`}
                                    >
                                        <StyledIcon>
                                            <HelpCenterIcon />
                                        </StyledIcon>
                                        <StyledText disableTypography>
                                            Help/Tutorial
                                        </StyledText>
                                    </StyledLink>
                                </StyledListItem>
                            </>
                        )}
                    {/* // End isServiceBinder. */}
                </StyledList>
            )}
        </>
    );
}

export default DrawerList;
