import Input from './Input';
import RadioGroup from './RadioGroup';
import Select from './Select';
import Checkbox from './Checkbox';
import BasicDatePicker from './BasicDatePicker';
import MultiSelect from './MultiSelect';
import MultiSelectGroupBy from './MultiSelectGroupBy';
import File from './File';
import DateTimePicker from './DateTimePicker';

const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    BasicDatePicker,
    MultiSelect,
    File,
    MultiSelectGroupBy,
    DateTimePicker,
};

export default Controls;
