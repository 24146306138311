import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useData } from '../../Context/Context';
import Controls from '../../Components/Controls/Controls';
import { useForm, Form } from '../../Components/UseForm';
import API from '../../libs/ServerAPIs';
import Spinner from '../../Components/Spinner';
import {
    Card,
    CardContent,
    CardActions,
    Box,
    Divider,
    Grid,
    Button,
    Alert,
    AlertTitle,
    Slide,
    Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import Modal from '../Modal';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

function ModalPreferences({ openModal, closeModal }) {
    /* VARIABLE DECLARATION
    -------------------------------------------------------------------------------------*/
    const { userData, primaryUser, checkAuth } = useData(useData);
    const [user_details] = userData;
    const [primary_user] = primaryUser;
    const location = useLocation();
    const [var_loading, set_loading] = useState(false);
    const [role_values, set_role_values] = useState('');
    const initValues = {
        id: 0,
        full_name: '',
        email: '',
        phone_number: '',
        ext: '',
        role: '',
        userRole: '',
        isAgreed: false,
        currentPassword: '***************',
        textMessages: false,
        pushNotifications: true,
        phoneCalls: false,
        disableRoleDropDown: true,
    };
    const {
        var_values,
        set_values,
        var_errors,
        set_errors,
        resetForm,
        handleInputChange,
    } = useForm(initValues);
    const { enqueueSnackbar } = useSnackbar();
    const [var_mdl_changePassword_open, set_mdl_changePassword_open] =
        useState(false);
    const [var_password_length, set_password_length] = useState(false);
    const [var_password_lowercase, set_password_lowercase] = useState(false);
    const [var_password_uppercase, set_password_uppercase] = useState(false);
    const [var_password_number, set_password_number] = useState(false);
    const [var_password_specialcharacter, set_password_specialcharacter] =
        useState(false);
    const [var_confirm_password, set_confirm_password] = useState(false);
    const [var_password_ready, set_password_ready] = useState(false);

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {
        checkPassword();
    }, [var_values.newPassword, var_values.confirmNewPassword]);
    useEffect(() => {
        if (
            var_password_length &&
            var_password_lowercase &&
            var_password_uppercase &&
            var_password_number &&
            var_password_specialcharacter &&
            var_confirm_password
        ) {
            set_password_ready(true);
        } else {
            set_password_ready(false);
        }
    }, [
        var_password_length,
        var_password_lowercase,
        var_password_uppercase,
        var_password_number,
        var_password_specialcharacter,
        var_confirm_password,
    ]);

    useEffect(() => {
        if (openModal) {
            checkmodalauth();
            resetForm();
        }
    }, [openModal, user_details]);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/
    async function checkmodalauth() {
        set_loading(true);
        const userInfo = await checkAuth();
        if (user_details.isAdmin || user_details.isContentManager) {
            getRolesLookup();
        }
        let dataUser;
        if (
            (location.pathname.includes('admin') &&
                primary_user?.isAdmin &&
                user_details?.isImpersonate) ||
            (location.pathname.includes('admin') &&
                primary_user?.isContentManager &&
                user_details?.isImpersonate) ||
            (location.pathname.includes('admin') &&
                primary_user?.isFieldUser &&
                user_details?.isImpersonate)
        ) {
            dataUser = primary_user;
            getBranchLookup(dataUser);
        } else if (
            user_details.isImpersonate &&
            user_details.isCustomer &&
            !user_details.isServiceBinder
        ) {
            getBranchLookup(user_details);
        } else {
            getBranchLookup(userInfo);
        }
    }

    async function getBranchLookup(UserData) {
        const requestUrl = `/api/v1/branch/lookup`;
        await API.get(requestUrl)
            .then((value) => {
                if (!value.error) {
                    var temp_val = '';

                    for (let val = 0; val < value.length; val++) {
                        if (UserData?.location?.branchID) {
                            if (val?.value === UserData?.location?.branchID)
                                temp_val = val?.label;
                        } else {
                            if (value[val]?.value === UserData?.defaultBranch) {
                                temp_val = value[val]?.label;
                            }
                        }
                    }
                    // value.map((val) => (

                    //     val.value === UserData?.location?.branchID ? UserData?.location?.branchID : UserData?.defaultBranch ? temp_val=val.label: ''
                    // ))
                    if (temp_val) {
                        set_values({
                            ...var_values,
                            id: UserData.id,
                            full_name: UserData?.location?.clientName
                                ? UserData?.location?.clientName
                                : UserData.fullName,
                            email: UserData.userName || '',
                            branch: temp_val,
                            ext: UserData?.location?.phoneNumberExt
                                ? UserData?.location?.phoneNumberExt
                                : UserData.phoneNumberExt || '',
                            phone_number: UserData?.location?.phone
                                ? UserData?.location?.phone
                                : UserData.phoneNumber || '',
                            role: UserData.role || '',
                        });
                    } else {
                        set_values({
                            ...var_values,
                            id: UserData.id,
                            full_name: UserData.fullName || '',
                            email: UserData.userName || '',
                            branch: '',
                            ext: UserData.phoneNumberExt || '',
                            phone_number: UserData.phoneNumber || '',
                            role: UserData.role || '',
                        });
                    }
                    set_loading(false);
                }
            })
            .catch((error) => {
                // console.log("Error:", error);
                return { error: true };
            });
    }

    async function getRolesLookup() {
        const requestUrl = `/api/v1/permission/lookup-roles`;
        await API.get(requestUrl)
            .then((value) => {
                if (!value.error) {
                    value.map((val) =>
                        val.label === user_details?.role
                            ? set_role_values(val.value)
                            : ''
                    );
                }
            })
            .catch((error) => {
                return { error: true };
            });
    }

    async function updateUser() {
        if (validate()) {
            set_loading(true);
            const requestUrl = `/api/v1/user/${user_details?.userID}`;
            await API.put(requestUrl, {
                fullName: var_values.full_name,
                username: user_details.userName,
                roleID: role_values,
                branchID: user_details.defaultBranch,
                phoneNumber: var_values.phone_number,
                phoneNumberExt: var_values.ext,
            })
                .then((value) => {
                    // API.get('/api/v1/auth').then(userInfo => {
                    //     if (!userInfo.error) {
                    //         setUserDetails(userInfo);

                    //     }
                    // })
                    closeModal();
                    set_loading(false);
                    showSnackbar(
                        value.error ? 'error' : 'success',
                        value.message
                            ? value.message
                            : value?.errors[0]?.message
                    );
                })
                .catch((error) => {
                    // console.log("Error:", error);
                    return { error: true };
                });
        }
    }

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    function showSnackbar(variant, message) {
        enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            TransitionComponent: Slide,
        });
    }

    function openMdlChangePassword() {
        set_mdl_changePassword_open(true);
    }

    function closeMdlChangePassword() {
        // resetForm()
        var_values.oldPassword = '';
        var_values.newPassword = '';
        var_values.confirmNewPassword = '';
        set_mdl_changePassword_open(false);
    }

    const handlePasswordUpdate = (e) => {
        let field_errors = {};
        const no_match_msg = 'Passwords do not match';

        ['oldPassword', 'newPassword', 'confirmNewPassword'].forEach(
            (field) => {
                if (field in var_values && var_values[field].trim() === '') {
                    field_errors = {
                        ...field_errors,
                        [field]: `This field must be provided with some value`,
                    };
                }

                if (
                    field === 'newPassword' &&
                    var_values.newPassword !== var_values.confirmNewPassword
                ) {
                    field_errors = {
                        ...field_errors,
                        newPassword: no_match_msg,
                        cNewPassword: no_match_msg,
                    };
                }
            }
        );

        set_errors(field_errors);
        // if no errors then send update password request
        if (Object.keys(field_errors).length === 0) {
            if (var_password_ready) {
                API.put('/api/v1/user/changePassword', {
                    confirmPassword: var_values.confirmNewPassword,
                    oldPassword: var_values.oldPassword,
                    newPassword: var_values.newPassword,
                }).then((response) => {
                    showSnackbar(
                        response.error ? 'error' : 'success',
                        response.message
                            ? response.message
                            : response?.errors[0]?.message
                    );
                    if (!response.error) {
                        //resetForm();
                        closeMdlChangePassword();
                    }
                });
            }
        }
    };

    function checkPassword() {
        if (var_values.newPassword) {
            let password = var_values.newPassword;

            if (password.length >= 8) {
                set_password_length(true);
            } else {
                set_password_length(false);
            }

            if (/[a-z]/.test(password)) {
                set_password_lowercase(true);
            } else {
                set_password_lowercase(false);
            }

            if (/[A-Z]/.test(password)) {
                set_password_uppercase(true);
            } else {
                set_password_uppercase(false);
            }

            if (/[0-9]/.test(password)) {
                set_password_number(true);
            } else {
                set_password_number(false);
            }
            // eslint-disable-next-line
            if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g.test(password)) {
                set_password_specialcharacter(true);
            } else {
                set_password_specialcharacter(false);
            }

            if (var_values.newPassword === var_values.confirmNewPassword) {
                set_confirm_password(true);
            } else {
                set_confirm_password(false);
            }
        } else {
            set_password_length(false);
            set_password_lowercase(false);
            set_password_uppercase(false);
            set_password_number(false);
            set_password_specialcharacter(false);
            set_confirm_password(false);
        }
    }

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }

    function validate() {
        let temp = { ...var_errors };
        var isValid = true;
        if (!var_values.full_name || var_values.full_name === '') {
            temp.full_name = 'Please enter Name.';
            isValid = false;
        }
        if (
            var_values.phone_number &&
            !formatPhoneNumber(var_values.phone_number)
        ) {
            temp.phone_number =
                'Please enter valid phone number e.g (123) 456-7890';
            isValid = false;
        }
        set_errors(temp);
        return isValid;
    }
    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <Modal
            title="Profile Settings"
            openModal={openModal}
            closeModal={closeModal}
            contentNoPadding
            width="sm"
            dialogActions={
                <>
                    <Button onClick={closeModal}>Close</Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={user_details?.isAdmin ? false : true}
                        onClick={updateUser}
                    >
                        Save
                    </Button>
                </>
            }
        >
            {var_loading && <Spinner />}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <Divider />

                        <CardContent sx={{ padding: '15px' }}>
                            <Grid container spacing={3}>
                                {user_details?.type !== 'sso' && (
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{ py: 1, textAlign: 'center' }}
                                        >
                                            <Box sx={{ mt: 2 }}>
                                                <Button
                                                    variant="contained"
                                                    onClick={
                                                        openMdlChangePassword
                                                    }
                                                >
                                                    Change Password
                                                </Button>
                                            </Box>
                                        </Box>
                                        <Divider sx={{ my: 3 }} />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Controls.Input
                                        name="full_name"
                                        label="Name"
                                        value={var_values.full_name}
                                        onChange={handleInputChange}
                                        error={var_errors.full_name}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controls.Input
                                        name="email"
                                        label="Email"
                                        disabled={true}
                                        value={var_values.email}
                                        onChange={handleInputChange}
                                        error={var_errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controls.Input
                                        name="role"
                                        label="User Role"
                                        value={var_values.role}
                                        disabled={true}
                                        onChange={handleInputChange}
                                        error={var_errors.role}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Controls.Input
                                        name="branch"
                                        label="Branch"
                                        value={var_values.branch || ''}
                                        onChange={handleInputChange}
                                        error={var_errors.branch}
                                        disabled={true}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Controls.Input
                                        name="phone_number"
                                        label="Phone Number"
                                        value={var_values.phone_number}
                                        onChange={handleInputChange}
                                        error={var_errors.phone_number}
                                        helperText="e.g (123) 456-7890"
                                        type="text"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <Controls.Input
                                        name="ext"
                                        label="Ext"
                                        value={var_values.ext}
                                        onChange={handleInputChange}
                                        error={var_errors.ext}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        <AlertTitle>
                                            Do you want to update your profile?
                                        </AlertTitle>
                                        Give us a call.​ —{' '}
                                        <strong>
                                            24/7 HOTLINE: 800.272.4988
                                        </strong>
                                    </Alert>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions
                            sx={{ px: '24px', pb: '20px', display: 'block' }}
                        ></CardActions>
                    </Card>
                </Grid>
            </Grid>
            {/***** MODAL: CHANGE PASSWORD ********************************************************/}
            <Modal
                title="Change password"
                openModal={var_mdl_changePassword_open}
                closeModal={closeMdlChangePassword}
                width="xs"
                dialogActions={
                    <>
                        <Button onClick={closeMdlChangePassword}>Cancel</Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handlePasswordUpdate}
                            disabled={
                                !var_password_ready ||
                                user_details?.location?.clientEmail
                                    ?.toLowerCase()
                                    .includes('demo')
                                    ? true
                                    : false
                            }
                        >
                            Save
                        </Button>
                    </>
                }
            >
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controls.Input
                                name="oldPassword"
                                label="Old password"
                                value={var_values.oldPassword}
                                error={var_errors.oldPassword}
                                onChange={handleInputChange}
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controls.Input
                                name="newPassword"
                                label="New password"
                                value={var_values.newPassword}
                                error={var_errors.newPassword}
                                onChange={handleInputChange}
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controls.Input
                                name="confirmNewPassword"
                                label="Confirm new password"
                                value={var_values.confirmNewPassword}
                                error={var_errors.confirmNewPassword}
                                onChange={handleInputChange}
                                type="password"
                            />
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            padding: '16px',
                            backgroundColor: '#EAEEF3',
                            borderRadius: '4px',
                            mt: '16px',
                        }}
                    >
                        <Typography variant="h5" sx={{ mb: '12px' }}>
                            Password must contain the following:
                        </Typography>
                        <Box
                            className="validation-item"
                            sx={{
                                display: 'flex',
                                color: var_password_lowercase
                                    ? 'success.main'
                                    : 'error.main',
                                fontSize: '14px',
                            }}
                        >
                            <Box
                                className="validation-icon"
                                sx={{ mr: '12px' }}
                            >
                                {var_password_lowercase ? (
                                    <CheckIcon sx={{ fontSize: '18px' }} />
                                ) : (
                                    <ClearIcon sx={{ fontSize: '18px' }} />
                                )}
                            </Box>
                            <Box className="validation-message">
                                A lowercase letter
                            </Box>
                        </Box>
                        <Box
                            className="validation-item"
                            sx={{
                                display: 'flex',
                                color: var_password_uppercase
                                    ? 'success.main'
                                    : 'error.main',
                                fontSize: '14px',
                            }}
                        >
                            <Box
                                className="validation-icon"
                                sx={{ mr: '12px' }}
                            >
                                {var_password_uppercase ? (
                                    <CheckIcon sx={{ fontSize: '18px' }} />
                                ) : (
                                    <ClearIcon sx={{ fontSize: '18px' }} />
                                )}
                            </Box>
                            <Box className="validation-message">
                                A capital (uppercase) letter
                            </Box>
                        </Box>
                        <Box
                            className="validation-item"
                            sx={{
                                display: 'flex',
                                color: var_password_number
                                    ? 'success.main'
                                    : 'error.main',
                                fontSize: '14px',
                            }}
                        >
                            <Box
                                className="validation-icon"
                                sx={{ mr: '12px' }}
                            >
                                {var_password_number ? (
                                    <CheckIcon sx={{ fontSize: '18px' }} />
                                ) : (
                                    <ClearIcon sx={{ fontSize: '18px' }} />
                                )}
                            </Box>
                            <Box className="validation-message">A number</Box>
                        </Box>
                        <Box
                            className="validation-item"
                            sx={{
                                display: 'flex',
                                color: var_password_specialcharacter
                                    ? 'success.main'
                                    : 'error.main',
                                fontSize: '14px',
                            }}
                        >
                            <Box
                                className="validation-icon"
                                sx={{ mr: '12px' }}
                            >
                                {var_password_specialcharacter ? (
                                    <CheckIcon sx={{ fontSize: '18px' }} />
                                ) : (
                                    <ClearIcon sx={{ fontSize: '18px' }} />
                                )}
                            </Box>
                            <Box className="validation-message">
                                A special character
                            </Box>
                        </Box>
                        <Box
                            className="validation-item"
                            sx={{
                                display: 'flex',
                                color: var_password_length
                                    ? 'success.main'
                                    : 'error.main',
                                fontSize: '14px',
                            }}
                        >
                            <Box
                                className="validation-icon"
                                sx={{ mr: '12px' }}
                            >
                                {var_password_length ? (
                                    <CheckIcon sx={{ fontSize: '18px' }} />
                                ) : (
                                    <ClearIcon sx={{ fontSize: '18px' }} />
                                )}
                            </Box>
                            <Box className="validation-message">
                                Minimum 8 characters
                            </Box>
                        </Box>

                        <Box
                            className="validation-item"
                            sx={{
                                display: 'flex',
                                color: var_confirm_password
                                    ? 'success.main'
                                    : 'error.main',
                                fontSize: '14px',
                            }}
                        >
                            <Box
                                className="validation-icon"
                                sx={{ mr: '12px' }}
                            >
                                {var_confirm_password ? (
                                    <CheckIcon sx={{ fontSize: '18px' }} />
                                ) : (
                                    <ClearIcon sx={{ fontSize: '18px' }} />
                                )}
                            </Box>
                            <Box className="validation-message">
                                Match password
                            </Box>
                        </Box>
                    </Box>
                </Form>
            </Modal>

            {/***** END MODAL: CHANGE PASSWORD ****************************************************/}
        </Modal>
    );
}

export default ModalPreferences;
