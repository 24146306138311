import appSettings from '../config';
// import Cookies from "js-cookies";

/*
    This class maintains the list of incomplete server requests, adds controller
    for their cancellation when required. The request is registered with the class
*/
class PendingRequestsManager {
    static #requestsList = [];

    static getRequests() {
        return PendingRequestsManager.#requestsList;
    }

    // Add request to request list
    static registerRequest(componentName, apiMethodName, apiUrl) {
        PendingRequestsManager.#requestsList = [
            ...PendingRequestsManager.#requestsList,
            {
                component: componentName,
                method: apiMethodName,
                requestUrl: apiUrl,
                abortController: null,
            },
        ];
    }

    // Abort/Cancel single request
    static abortRequest(componentName, requestMethod) {
        let index = 0;
        let abortPromise = new Promise((resolve) => {
            for (let request of PendingRequestsManager.#requestsList) {
                if (
                    request['component'] === componentName &&
                    request['method'] === requestMethod
                ) {
                    request['abortController']?.abort();
                    PendingRequestsManager.#requestsList.splice(index, 1);
                    break;
                }
                index++;
            }
            resolve(PendingRequestsManager.#requestsList); // provide the remaining list as resolved value
        });
        abortPromise.then((resolvedValue) => {});
    }

    // Same as abortRequest but accepts list of requests
    static abortRequests(requests = {}) {
        if ('component' in requests) {
            requests['requestMethods'].forEach((apiMethod) => {
                PendingRequestsManager.abortRequest(
                    requests.component,
                    apiMethod
                );
            });
        }
    }

    // Add controller handle to newly initiated request
    static addAbortControllerToRequest(apiUrl, abortController) {
        for (let request of PendingRequestsManager.#requestsList) {
            if (
                request['requestUrl'] === apiUrl &&
                request['abortController'] === null
            ) {
                request['abortController'] = abortController;
                break;
            }
        }
    }

    // Delete request once it is finished
    static clearRequest(key, value) {
        if (key === 'method' || key === 'requestUrl') {
            let index = 0;
            for (let request of PendingRequestsManager.#requestsList) {
                if (request[key] === value) {
                    PendingRequestsManager.#requestsList.splice(index, 1);
                    break;
                }
                index++;
            }
        }
    }
}

const ABORT_REQUEST_CONTROLLERS = new Map();

function abortRequestSafe(key, reason = 'CANCELLED') {
    ABORT_REQUEST_CONTROLLERS.get(key)?.abort?.(reason);
}

function abortAndGetSignalSafe(key) {
    abortRequestSafe(key); // abort previous request, if any
    const newController = new AbortController();
    ABORT_REQUEST_CONTROLLERS.set(key, newController);
    return newController.signal;
}

const createRequestObject = (
    method = 'GET',
    path = '',
    isLogbookReq = false,
    body = null,
    isFormData = false
) => {
    const requestOptions = {
        method,
        headers: { 'Content-Type': 'application/json' },
    };

    if (window?.location?.hostname === 'localhost' && isLogbookReq) {
        requestOptions.headers['Authorization'] =
            'Bearer ' + localStorage.getItem('tokenDoc');
    }

    if (body) {
        requestOptions.body = JSON.stringify(body);
    }

    if (isFormData) {
        if (isLogbookReq) {
            delete requestOptions.headers['Content-Type'];
        } else {
            delete requestOptions.headers;
        }
        requestOptions.body = body;
    }

    let apiPath = isLogbookReq ? appSettings.portalDocURL + path : path;
    if (isLogbookReq) {
        if (appSettings.isNginx) {
            apiPath = path;
        } else {
            requestOptions.headers['X-Auth-Key'] = 'iL0veP@k!$tAn';
            requestOptions.headers['x-portal-name'] = 'LOGBOOK PORTAL';
        }
    }

    const pathWithoutQueryParams = path.split('?');
    if (pathWithoutQueryParams.length > 1) {
        const queryParamsValue = pathWithoutQueryParams[1].split('=');
        if (queryParamsValue.length > 1 && queryParamsValue[1] !== '') {
            const signalKey =
                window.location.pathname +
                method +
                pathWithoutQueryParams[0] +
                isLogbookReq +
                isFormData;
            requestOptions.signal = abortAndGetSignalSafe(signalKey);
        }
    }

    return { apiPath, requestOptions };
};

async function get(path, isLogbookReq = false) {
    const { apiPath, requestOptions } = createRequestObject(
        'GET',
        path,
        isLogbookReq
    );

    const result = await fetchApi(apiPath, requestOptions);
    return result;
}

async function post(path, body = {}, isFormData = false, isLogbookReq = false) {
    const { apiPath, requestOptions } = createRequestObject(
        'POST',
        path,
        isLogbookReq,
        body,
        isFormData
    );

    const result = await fetchApi(apiPath, requestOptions);
    return result;
}

async function put(path, body = {}, isFormData = false, isLogbookReq = false) {
    const { apiPath, requestOptions } = createRequestObject(
        'PUT',
        path,
        isLogbookReq,
        body,
        isFormData
    );

    const result = await fetchApi(apiPath, requestOptions);
    return result;
}

async function patch(
    path,
    body = {},
    isFormData = false,
    isLogbookReq = false
) {
    const { apiPath, requestOptions } = createRequestObject(
        'PATCH',
        path,
        isLogbookReq,
        body,
        isFormData
    );

    const result = await fetchApi(apiPath, requestOptions);
    return result;
}

async function remove(path, isLogbookReq = false) {
    const { apiPath, requestOptions } = createRequestObject(
        'DELETE',
        path,
        isLogbookReq
    );

    const result = await fetchApi(apiPath, requestOptions);
    return result;
}

async function fetchApi(path, requestOptions) {
    /* Abort Requests */
    if (!requestOptions.signal) {
        let abortController = new AbortController();
        PendingRequestsManager.addAbortControllerToRequest(
            path,
            abortController
        );
        requestOptions.signal = abortController.signal;
    }
    /* Abort Requests */

    return fetch(path, requestOptions)
        .then((res) => {
            if (
                !res.ok &&
                res.status === 403 &&
                window.location.pathname !== '/'
            ) {
                const url_encode = window.location.pathname;
                const params = new URLSearchParams(window.location.search);
                window.location.replace(
                    `/?ref=${encodeURIComponent(
                        url_encode + `?${params.toString()}`
                    )}`
                );
                return { error: true, message: 'Unauthorized user' };
            }
            if (
                !res.ok &&
                res.status === 401 &&
                window.location.pathname !== '/'
            ) {
                if (res.isServiceBinder && res.friendlyURL) {
                    window.location.replace(
                        `/service-binder/${res.friendlyURL}}`
                    );
                    return { error: true, message: 'Unauthorized user' };
                }
                const url_encode = window.location.pathname;
                const params = new URLSearchParams(window.location.search);
                window.location.replace(
                    `/?ref=${encodeURIComponent(
                        url_encode + `?${params.toString()}`
                    )}`
                );
                return { error: true, message: 'Unauthorized user' };
            }
            if (res.status > 499) {
                return {
                    error: true,
                    statusCode: res.status,
                    message: res.statusText,
                };
            }
            // Clear request if completed successfully
            PendingRequestsManager.clearRequest('requestUrl', path);

            const responseType =
                res.headers.get('Content-Type') ||
                res.headers.get('content-type');

            if (responseType.indexOf('application/json') === -1) {
                return res.blob();
            }
            return res.json();
        })
        .catch((err) => {
            // Clear request in case of error too
            PendingRequestsManager.clearRequest('requestUrl', path);
            return {
                error: true,
                continueLoading: err.name === 'AbortError',
                message:
                    err.name === 'AbortError' ? 'Abort Request' : err || '',
            };
        });
}

const APIServer = {
    get,
    post,
    put,
    patch,
    remove,
    fetchApi,
    PendingRequestsManager,
};

export default APIServer;
